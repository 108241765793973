import Logger from 'src/lib/Logger';
class Tasks {
  /**
   * For caching, search mode, etc
   */

  tasks = {};
  tasksList = [];

  setContext(profile_context) {
    this.profile_context = profile_context;
  }

  cache(taskId = '', uRef = '', payload) {
    taskId = taskId.toLowerCase();
    Logger.log(`Setting cache for: ${taskId} ${uRef}`);
    if (taskId && uRef && payload && typeof payload === 'object') {
      if (this.tasks[`${taskId}`]) {
        if (payload && payload.deleted) {
          return delete this.tasks[`${taskId}`];
        }

        this.tasks[`${taskId}`] = {
          latest: { ...this.tasks[`${taskId}`].latest, ...payload },
          deleted: payload.deleted || false
        };
      } else {
        this.tasks[`${taskId}`] = {
          taskId,
          uRef,
          latest: { ...payload },
          deleted: payload.deleted || false
        };
      }
    }
  }

  getCachedTaskById(taskId = '') {
    taskId = taskId.toLowerCase().trim();

    if (!taskId) {
      return null;
    }

    return this.tasks[taskId];
  }
}

export default Tasks;
