import axios from 'axios';
import Logger from 'src/lib/Logger';
import k from 'src/constants/k';
import Store from 'src/lib/store';
import ProfileAPI from 'src/profile-manager/API';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

class UserLog {
  postQ = [];
  postOBS = null;
  postOBSSubscriber = null;

  constructor() {
    this.postOBS = new Subject();
    this.postOBSSubscriber = this.postOBS
      .pipe(debounceTime(200))
      .subscribe(this.onConfirmPost.bind(this));
  }

  destroy() {
    if (this.postOBSSubscriber) {
      this.postOBSSubscriber.unsubscribe();
    }

    if (this.postOBS) {
      this.postOBS.unsubscribe();
    }
  }

  post(code, severity = '', params = {}) {
    try {
      if (this.postOBS) {
        this.postQ.push({
          code,
          severity,
          params
        });
        this.postOBS.next();
      }
    } catch (err) {
      if (err) {
        Logger.log(err.message);

        if (err.stack) {
          Logger.log(err.stack);
        }
      }
    }
  }

  async onConfirmPost() {
    const q = this.postQ.shift();

    if (
      q &&
      q.code &&
      q.params &&
      typeof q.params === 'object' &&
      Object.keys(q.params).length < 6 &&
      ProfileAPI.USER_DATA.checkIsLoggedIn()
    ) {
      try {
        const user = ProfileAPI.USER_DATA.info();
        const { token, id, lb, n, username_ref } = user.auth;
        const { code = '', severity = '', params = {} } = q;

        await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            code,
            severity,
            params
          },
          url: `/v1/pub/u/log?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });
      } catch (err) {
        if (err) {
          Logger.log(err.message);

          if (err.stack) {
            Logger.log(err.stack);
          }
        }
      }
    }
  }
}

export default new UserLog();
