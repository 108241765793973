import React, { Fragment } from 'react';
import * as en from './en.json';
import { Link } from 'react-router-dom';

const DEFAULT_LOCALE = 'en';
const LOCALES = {
  en: en.default
};
const DEFAULT_LANG = LOCALES[DEFAULT_LOCALE];

let LANG = DEFAULT_LANG;
let reqCounterMarkdown = 1;
let reqCounterMarkDownURL = 1;

export function setLanguage(lang) {
  if (Object.keys(LOCALES).includes(lang)) {
    LANG = LOCALES[lang];
  }
}

export default function i18n(key) {
  const str = LANG[key] || DEFAULT_LANG[key] || '';
  return str;
}

export function Markdown(unique, vars, className) {
  const str = `${
    LANG[unique]
      ? LANG[unique]
      : DEFAULT_LANG[unique]
      ? DEFAULT_LANG[unique]
      : ''
  }`;

  if (!vars || typeof vars !== 'object') return str;

  const children = Object.keys(vars).reduce((acc, key, index) => {
    const cKey = `$${key}`;
    let cloneChildren = [...acc.props.children];
    let i = 0;
    let accSTR = '';
    reqCounterMarkdown += 1;

    if (acc && acc.props.children && acc.props.children.length > 0) {
      while (i < acc.props.children.length) {
        const child = acc.props.children[i];

        if (typeof child === 'string' && child.indexOf(cKey) >= 0) {
          accSTR = child;
          break;
        }

        i += 1;
      }
    }

    if (accSTR.length > 0) {
      const first = accSTR.indexOf(cKey);
      const last = accSTR.lastIndexOf(cKey);
      const firstHalf = accSTR.substring(0, first);
      const secondHalf = accSTR.substring(last + cKey.length, accSTR.length);
      const newSTR =
        typeof vars[key] === 'string'
          ? vars[key]
          : accSTR.substring(first + cKey.length, last);
      const markDOM =
        vars[key] && typeof vars[key].link === 'string' ? (
          <Link to={`${vars[key].link}`}>{newSTR}</Link>
        ) : (
          React.createElement(
            'span',
            {
              key: `key-markdown-${unique}-${key}-${index}-${reqCounterMarkdown}`
            },
            newSTR
          )
        );
      cloneChildren[i] = '';

      if (i === 0) {
        if (firstHalf.length > 0) {
          cloneChildren.splice(i, 0, markDOM);
          cloneChildren.splice(i, 0, firstHalf);
        }

        if (secondHalf.length > 0 && firstHalf.length > 0) {
          cloneChildren.splice(2, 0, secondHalf);
        } else if (secondHalf.length > 0) {
          cloneChildren.splice(i, 0, secondHalf);
          cloneChildren.splice(i, 0, markDOM);
        }
      } else if (i === cloneChildren.length - 1) {
        if (firstHalf.length > 0) {
          cloneChildren.splice(i, 0, markDOM);
          cloneChildren.splice(i, 0, firstHalf);
        }

        if (secondHalf.length > 0 && firstHalf.length > 0) {
          cloneChildren.push(secondHalf);
        } else if (secondHalf.length > 0) {
          cloneChildren.splice(i, 0, secondHalf);
          cloneChildren.splice(i, 0, markDOM);
        }
      } else {
        if (firstHalf.length > 0) {
          cloneChildren.splice(i, 0, markDOM);
          cloneChildren.splice(i, 0, firstHalf);
        }

        if (secondHalf.length > 0 && firstHalf.length > 0) {
          cloneChildren.splice(
            cloneChildren.indexOf(markDOM) + 1,
            0,
            secondHalf
          );
        } else if (secondHalf.length > 0) {
          cloneChildren.splice(i, 0, secondHalf);
          cloneChildren.splice(i, 0, markDOM);
        }
      }

      return (
        <Fragment
          key={`key-markdown-${unique}-${key}-${index}-${reqCounterMarkdown}-fragment`}
        >
          {cloneChildren}
        </Fragment>
      );
    } else {
      return acc;
    }
  }, <Fragment> {str}</Fragment>);

  return React.createElement(
    'p',
    { ...(className && { className }) },
    children
  );
}

export function URLMarkdown(unique, vars, className, forRedirect = true) {
  const str = `${
    LANG[unique]
      ? LANG[unique]
      : DEFAULT_LANG[unique]
      ? DEFAULT_LANG[unique]
      : ''
  }`;

  if (!vars || typeof vars !== 'object') return str;

  const children = Object.keys(vars).reduce((acc, key, index) => {
    const cKey = `$${key}`;
    let cloneChildren = [...acc.props.children];
    let i = 0;
    let accSTR = '';

    if (acc && acc.props.children && acc.props.children.length > 0) {
      while (i < acc.props.children.length) {
        const child = acc.props.children[i];

        if (typeof child === 'string' && child.indexOf(cKey) >= 0) {
          accSTR = child;

          break;
        }

        i += 1;
      }
    }

    if (accSTR.length > 0) {
      const first = accSTR.indexOf(cKey);
      const last = accSTR.lastIndexOf(cKey);
      const firstHalf = accSTR.substring(0, first);
      const secondHalf = accSTR.substring(last + cKey.length, accSTR.length);
      const newSTR =
        typeof vars[key] === 'string'
          ? vars[key]
          : accSTR.substring(first + cKey.length, last);
      const url = vars[key].link ? vars[key].link : '';
      const markDOM = forRedirect ? (
        vars[key].notRedirect ? (
          <Link
            key={`key-url-markdown-${unique}-${key}-${index}`}
            to={vars[key].link}
          >
            {vars[key].text || newSTR}
          </Link>
        ) : (
          React.createElement(
            'a',
            {
              href: url,
              ...(vars[key].onClick && { onClick: vars[key].onClick }),
              ...{
                key: `key-url-markdown-${unique}-${key}-${index}-${reqCounterMarkDownURL++}`
              }
            },
            vars[key].text || newSTR
          )
        )
      ) : (
        <Link key={`key-url-markdown-${unique}-${key}-${index}`} to={`${url}`}>
          {newSTR}
        </Link>
      );
      cloneChildren[i] = '';

      if (i === 0) {
        if (firstHalf.length > 0) {
          cloneChildren.splice(i, 0, markDOM);
          cloneChildren.splice(i, 0, firstHalf);
        }

        if (secondHalf.length > 0 && firstHalf.length > 0) {
          cloneChildren.splice(2, 0, secondHalf);
        } else if (secondHalf.length > 0) {
          cloneChildren.splice(i, 0, secondHalf);
          cloneChildren.splice(i, 0, markDOM);
        }
      } else if (!cloneChildren[i + 1]) {
        if (firstHalf.length > 0) {
          cloneChildren.splice(i, 0, markDOM);
          cloneChildren.splice(i, 0, firstHalf);
        }

        if (secondHalf.length > 0 && firstHalf.length > 0) {
          cloneChildren.push(secondHalf);
        } else if (secondHalf.length > 0) {
          cloneChildren.splice(i, 0, secondHalf);
          cloneChildren.splice(i, 0, markDOM);
        }

        if (firstHalf.length <= 0 && secondHalf.length <= 0) {
          cloneChildren.splice(i, 0, markDOM);
        }
      } else {
        if (firstHalf.length > 0) {
          cloneChildren.splice(i, 0, markDOM);
          cloneChildren.splice(i, 0, firstHalf);
        }

        if (secondHalf.length > 0 && firstHalf.length > 0) {
          cloneChildren.splice(
            cloneChildren.indexOf(markDOM) + 1,
            0,
            secondHalf
          );
        } else if (secondHalf.length > 0) {
          cloneChildren.splice(i, 0, secondHalf);
          cloneChildren.splice(i, 0, markDOM);
        }
      }

      return <Fragment>{cloneChildren}</Fragment>;
    } else {
      return acc;
    }
  }, <Fragment> {str}</Fragment>);

  return React.createElement(
    'p',
    { ...(className && { className }) },
    children
  );
}
