import React, { Component, Fragment } from 'react';
import ModalAPI from './API';
import { fromEvent } from 'rxjs';

class ModalManager extends Component {
  localOnDocumentKeydownSubscriber = null;

  coverStyle = {
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    height: '100%',
    width: '100%',
    display: 'block'
  };

  centerAllStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  state = {
    active: false,
    localDOMREF: null,
    localDOMREF2: null,
    mounted: false,
    noEscape: false
  };

  constructor() {
    super();

    this.onKeydown = this.onKeydown.bind(this);
    ModalAPI.setContext(this);
  }

  componentDidMount() {
    this.localOnDocumentKeydownSubscriber = fromEvent(
      window,
      'keydown'
    ).subscribe(this.onKeydown);

    this.setState({
      mounted: true
    });
  }

  componentWillUnmount() {
    this.setState({
      mounted: false
    });

    if (this.localOnDocumentKeydownSubscriber) {
      this.localOnDocumentKeydownSubscriber.unsubscribe();
    }
  }

  onKeydown(evt) {
    if (evt && evt.keyCode === 27) {
      const { mounted, active, localDOMREF, noEscape } = this.state;

      if (noEscape) {
        return;
      } else if (active && localDOMREF && mounted) {
        this.setState({
          active: false,
          localDOMREF: null,
          localDOMREF2: null
        });
      }
    }
  }

  render() {
    const { active, localDOMREF, localDOMREF2 } = this.state;

    return (
      <Fragment>
        <div
          style={{
            ...this.coverStyle,
            ...(active && {
              filter: `blur(4px)`,
              overflow: 'hidden',
              msUserSelect: 'none',
              userSelect: 'none',
              MozUserSelect: '-moz-none'
            })
          }}
        >
          {this.props.children}
        </div>
        {(active && (
          <Fragment>
            <div
              style={{
                ...this.coverStyle,
                ...this.centerAllStyle,
                ...(localDOMREF2 && {
                  filter: `blur(3px)`,
                  overflow: 'hidden'
                }),
                ...(active && {
                  background: 'transparent',
                  zIndex: 5,
                  position: 'fixed'
                })
              }}
            >
              {localDOMREF}
            </div>
            {localDOMREF2 && (
              <div
                style={{
                  ...this.coverStyle,
                  ...this.centerAllStyle,
                  background: 'transparent',
                  zIndex: 6,
                  position: 'fixed'
                }}
              >
                {localDOMREF2}
              </div>
            )}
          </Fragment>
        )) || <Fragment />}
      </Fragment>
    );
  }
}

export default ModalManager;
