import React from 'react';
import cx from 'classnames';
import styles from './InitUser.scss';
import LoadSpinner from 'src/components/load-spinner';
import i18n from 'src/locales';

const InitUserLoading = () => {
  return (
    <div className={cx(styles.flex_column_xy, styles.init_user_wrap)}>
      <LoadSpinner />
      <div className={styles.msg}>
        <h4>{i18n('init_user_message')}</h4>
      </div>
    </div>
  );
};

export default InitUserLoading;
