class UserDOM {
  IS_VALID = true;

  NOT_ALLOWED = [
    'video',
    'textarea',
    'input',
    'script',
    'style',
    'styles',
    'link',
    // 'iframe', include in whitelist for now for twitter widget support
    'frame',
    'html',
    'body',
    'head',
    'meta',
    'title'
  ];

  notAllowed(dom) {
    if (
      this.isElement(dom) &&
      this.NOT_ALLOWED.indexOf(`${dom.nodeName}`.toLowerCase()) >= 0
    ) {
      return true;
    }

    return false;
  }

  isImage(dom) {
    return (
      this.isElement(dom) &&
      (dom.nodeName.toLowerCase() === 'img' ||
        (!!window.HTMLImageElement && dom instanceof window.HTMLImageElement))
    );
  }

  isVideo(dom) {
    return (
      this.isElement(dom) &&
      (dom.nodeName.toLowerCase() === 'video' ||
        dom.nodeName.toLowerCase() === 'vid' ||
        (!!window.HTMLVideoElement && dom instanceof window.HTMLVideoElement))
    );
  }

  isScript(dom) {
    return (
      this.isElement(dom) &&
      (dom.nodeName.toLowerCase() === 'script' ||
        (!!window.HTMLScriptElement && dom instanceof window.HTMLScriptElement))
    );
  }

  isElement(dom) {
    return (
      (typeof window.HTMLElement === 'object' && dom instanceof HTMLElement) ||
      (!!dom &&
        typeof dom === 'object' &&
        dom !== null &&
        (!!dom.nodeType || typeof dom.textContent === 'string'))
    );
  }

  parseString(str = '') {
    /**
     * String to html DOM
     */

    const dom = document.createElement('div');
    dom.innerHTML = `${str}`;

    return dom.firstChild;
  }
}

export default new UserDOM();
