import axios from 'axios';
import k from 'src/constants/k';
import Store from 'src/lib/store';
import Logger from 'src/lib/Logger';
import { Subject } from 'rxjs';

class UserProfile {
  onUpdateDPOBS = null;
  onNewDPOBS = null;
  onProfileIDInfoOBS = null;
  onEmailInfoOBS = null;
  onSaveDraftOBS = null;
  onGetLastDraftOBS = null;
  profile_context = null;

  constructor() {
    this.onUpdateDPResultOBS = new Subject();
    this.onNewDPOBS = new Subject();
    this.onProfileIDInfoOBS = new Subject();
    this.onSaveDraftOBS = new Subject();
    this.onGetLastDraftOBS = new Subject();
    this.onEmailInfoOBS = new Subject();
  }

  setContext(profile_context) {
    this.profile_context = profile_context;
  }

  confirmJustLoggedIn() {
    if (this.profile_context) {
      this.profile_context.setState({
        justLoggedIn: true
      });
    }
  }

  onUpdateDPResult(fn) {
    if (this.onUpdateDPResultOBS) {
      return this.onUpdateDPResultOBS.subscribe({ next: fn });
    }

    return null;
  }

  onNewDP(fn) {
    if (this.onNewDPOBS) {
      return this.onNewDPOBS.subscribe({ next: fn });
    }

    return null;
  }

  async updateDP(base64 = '') {
    if (
      !this.profile_context ||
      base64.length <= 0 ||
      base64.indexOf('data:image/jpeg') !== 0
    ) {
      return;
    }

    try {
      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn) {
        return;
      }

      const { token, id, lb, n, username_ref } = user.auth;
      const res = await axios({
        method: 'PUT',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          base64
        },
        url: `/v1/user/update/dp?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.status === 200 && res.data && res.data.success) {
        user.dp = base64;

        await this.profile_context.setStateAsync({
          user
        });

        this.onUpdateDPResultOBS.next({
          success: true,
          base64,
          limit_reached: false,
          limit_next: res.data.limit_next
        });

        this.onNewDPOBS.next({
          updated: true
        });
      } else if (res.data && res.data.limit_reached) {
        this.onUpdateDPResultOBS.next({
          limit_reached: true,
          limit_next: res.data.limit_next
        });
      } else {
        this.onUpdateDPResultOBS.next({
          success: false
        });
      }
    } catch (err) {
      Logger.log(`Failed to update user DP: ${err.message}`);

      this.onUpdateDPResultOBS.next({ success: false });
    }
  }

  onIDInfo(fn) {
    if (this.onProfileIDInfoOBS) {
      return this.onProfileIDInfoOBS.subscribe({ next: fn });
    }

    return null;
  }

  onEmailInfo(fn) {
    if (this.onEmailInfoOBS) {
      return this.onEmailInfoOBS.subscribe({ next: fn });
    }

    return null;
  }

  async getEmailInfo(email = '') {
    if (!this.profile_context) {
      return;
    }

    try {
      const { user = {}, isLoggedIn = false } = this.profile_context.state;

      if (!isLoggedIn) {
        return;
      }

      const { token, id, lb, n, username_ref = '' } = user.auth;
      const res = await axios({
        method: 'POST',
        baseURL:
          k.API_OPEN_URLS[Store.CONFIGS.IS_DEV ? 'development' : 'production'],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          email
        },
        url: `/v1/user/info-mail?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data) {
        return res.data;
      } else {
        return null;
      }
    } catch (err) {
      if (err) {
        Logger.log(err.stack);
      }

      return null;
    }
  }

  async IDInfo(id = '') {
    if (!this.profile_context) {
      return;
    }

    try {
      const res = await axios({
        method: 'POST',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        data: {
          id
        },
        url: `/v1/task/pid`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.status === 200 && res.data && res.data.valid) {
        this.onProfileIDInfoOBS.next({
          id,
          valid: true,
          has_image: res.data.has_image,
          image: res.data.image,
          firstName: res.data.firstName,
          lastName: res.data.lastName
        });
      } else {
        this.onProfileIDInfoOBS.next({
          id,
          valid: false,
          image: '',
          has_image: false
        });
      }
    } catch (err) {
      if (err && err.stack) {
        Logger.log(err.stack);
      }

      Logger.log(`Failed to post info w/ ID: ${id}: ${err.message}`);
      this.onProfileIDInfoOBS.next({
        id,
        valid: false,
        image: '',
        has_image: false
      });
    }
  }

  onSaveDraftResult(fn) {
    if (this.onSaveDraftOBS) {
      return this.onSaveDraftOBS.subscribe({ next: fn });
    }

    return null;
  }

  async saveDraft(title = '', description_delta = '') {
    try {
      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn) {
        return;
      }

      const { token, id, lb, n, username_ref } = user.auth;
      const res = await axios({
        method: 'PUT',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        data: {
          title,
          description_delta,
          subscribers: []
        },
        url: `/v1/task/save-draft?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data && typeof res.data === 'object') {
        this.onSaveDraftOBS.next({ ...res.data });
      }
    } catch (err) {
      if (err && err.stack) {
        Logger.log(err.stack);
      }

      Logger.log(`Failed saving user's last task draft: ${err.message}`);
    }
  }

  onGetLastDraftResult(fn) {
    if (this.onGetLastDraftOBS) {
      return this.onGetLastDraftOBS.subscribe({ next: fn });
    }

    return null;
  }

  getDraftTimestamp() {
    return Math.ceil(Date.now() / (10 * 1000));
  }

  async getLastDraft() {
    try {
      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn) {
        return;
      }

      const { token, id, lb, n, username_ref } = user.auth;
      const res = await axios({
        method: 'GET',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        headers: {
          authorization: `BEARER ${token}`,
          'x-custom-user-session': `${username_ref}`
        },
        url: `/v1/task/last-draft?auth_token_id=${id}&lb=${lb}&n=${n}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.status === 200 && res.data.valid) {
        this.onGetLastDraftOBS.next({
          valid: true,
          title: res.data.title,
          created: res.data.created,
          u_ref: res.data.u_ref,
          description_delta: res.data.description_delta
        });
      } else {
        this.onGetLastDraftOBS.next({
          valid: false
        });
      }
    } catch (err) {
      Logger.log(err.stack || '');
      Logger.log(err.message);

      this.onGetLastDraftOBS.next({
        valid: false
      });
    }
  }

  async report(opt = [], comment = '') {
    if (this.profile_context) {
      const { user = {}, isLoggedIn } = this.profile_context.state;

      if (!isLoggedIn || !Array.isArray(opt)) {
        return null;
      }

      try {
        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            opt,
            comment
          },
          url: `/v1/pub/report?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        Logger.log(
          `Received status: ${res.status} from sending feedback report from user`
        );

        if (res && res.data) {
          return res.data;
        } else {
          return null;
        }
      } catch (err) {
        Logger.log(err.stack || '');
        Logger.log(err.message);

        return null;
      }
    }
  }
}

export default UserProfile;
