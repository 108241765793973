import React, { Component, Fragment } from 'react';
import UserLog from 'src/lib/UserLog';
import Store from 'src/lib/store';
import ProfileAPI from 'src/profile-manager/API';

class ErrorBound extends Component {
  state = {
    hasError: false
  };

  componentDidCatch(err, errInfo) {
    const str =
      err && typeof err === 'string'
        ? err
        : err && err.message
        ? err.message
        : '';

    console.error(`${str}: ${errInfo} `);
    // @todo send to analytics service endpoint
    if (ProfileAPI.USER_DATA.checkIsLoggedIn()) {
      UserLog.post('dashboard_error_bound_reached', 'Critical', {
        message: `Error bound reached in dashboard FE: ${Store.CONFIGS.app.clientVersion}, err: ${errInfo} ${str}`
      });
    }

    this.setState({
      hasError: !!(err || errInfo)
    });
  }

  render() {
    return (
      <Fragment>
        {this.props.children}
        {
          null
          /**
           *  We need to show error page here and hide the children DOM */
        }
      </Fragment>
    );
  }
}

export default ErrorBound;
