import React, { Component, lazy, Suspense } from 'react';
import LogoLoad from 'src/components/logo-load';
import ForgotPassword from './forgot-password';
import Docs from './docs';
import { Switch, Route } from 'react-router-dom';

const Login = lazy(() => import('./loginv2')); 
const Signup = lazy(() => import('./sign-upv2'));
const User = lazy(() => import('./user'));
const Default = lazy(() => import('./default'));
const NotFound = lazy(() => import('./user/not-found'));
const TaskView = lazy(() => import('./task'));
const ResetPassword = lazy(() => import('./reset-password'));
const BetaInvite = lazy(() => import('./beta-invite'));

class DefaultEntry extends Component {
  render() {
    return (
      <Suspense fallback={<LogoLoad />}>
        <Switch>
          <Route path={'/login'}>
            <Login />
          </Route>
          <Route path={'/sign-up'}>
            <Signup />
          </Route>
          <Route path={'/docs'}>
            <Docs />
          </Route>
          <Route path={'/forgot-password'}>
            <ForgotPassword />
          </Route>
          <Route path={'/reset-password'}>
            <ResetPassword />
          </Route>
          <Route path={'/view'}>
            <TaskView />
          </Route>
          <Route path={'/user/beta'}>
            <BetaInvite />
          </Route>
          <Route path={['/user']}>
            <User />
          </Route>
          <Route path={'/not-found'}>
            <NotFound />
          </Route>
          <Route exact path={['/', '/index', '/home']}>
            <Default />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    );
  }
}

export default DefaultEntry;
