import React, { Component, Fragment } from 'react';
import BottomPromptAPI from './API';

class BottomPromptManager extends Component {
  state = {
    mounted: false,
    active: false,
    promptDOM: null
  };

  constructor() {
    super();

    BottomPromptAPI.setContext(this);
  }

  componentDidMount() {
    this.setState({
      mounted: true
    });
  }

  componentWillUnmount() {
    this.setState({
      promptDOM: null
    });
  }

  setPromptDOM(d) {
    this.setState({
      promptDOM: d,
      active: true
    });
  }

  deactivatePromptDOM() {
    this.setState({
      active: false,
      promptDOM: null
    });
  }

  render() {
    const { active, promptDOM } = this.state;
    return (
      <Fragment>
        {this.props.children}
        <div
          style={{
            display: active ? 'block' : 'none',
            position: 'fixed',
            bottom: '0',
            left: 0,
            right: 0,
            maxHeight: '100px',
            minHeight: '55px',
            zIndex: 5 // high_front under commons.scss
          }}
        >
          {promptDOM || null}
        </div>
      </Fragment>
    );
  }
}

export default BottomPromptManager;
