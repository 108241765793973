import axios from 'axios';
import k from 'src/constants/k';
import Store from 'src/lib/store';
import Logger from 'src/lib/Logger';
import ProfileAPI from 'src/profile-manager/API';
import { Subject } from 'rxjs';
import { v4 } from 'uuid';

class Public {
  static resultFromLoginOBS = null;
  static resultFromSignupOBS = null;
  static resultFromInspectTaskOBS = null;
  static resultFromInspectTaskPWOBS = null;
  static resultsFromSubmitForgotPW = null;
  static resultsFromVerifyResetPWToken = null;
  static resultsFromResetPWOBS = null;
  static onHideSearchbarOBS = null;

  VARS = {
    gettingTaskInfo: false,
    inspectTaskQueue: [],
    inspectTaskMap: {}, // taskID => data
    hideSearch: false
  };

  constructor() {
    this.resultFromLoginOBS = new Subject();
    this.resultFromSignupOBS = new Subject();
    this.resultFromInspectTaskOBS = new Subject();
    this.resultFromInspectTaskPWOBS = new Subject();
    this.onHideSearchbarOBS = new Subject();
    this.resultsFromSubmitForgotPW = new Subject();
    this.resultsFromVerifyResetPWToken = new Subject();
    this.resultsFromResetPWOBS = new Subject();
    this.showSearchbar = this.showSearchbar.bind(this);
    this.hideSearchbar = this.hideSearchbar.bind(this);
  }

  resetVARS() {
    this.VARS.gettingTaskInfo = false;
    this.VARS.inspectTaskQueue = [];
    this.VARS.inspectTaskMap = {};
    this.VARS.hideSearch = false;
  }

  setContext(profile_context) {
    this.profile_context = profile_context;
  }

  randomFromZeroBeyond(min, max) {
    let ran = Math.random();
    return Math.floor(ran * (max - min) + min);
  }

  enc(pw = '', _arr1_, _arr2_) {
    const _kshift = this.randomFromZeroBeyond(1, 9);
    const _arr1L_ = 62;

    if (typeof pw === 'string' && _arr1_ && _arr2_) {
      const pwL = pw.length;
      let valueArr = [`${_kshift}`];
      let idx = 0;

      while (idx < pwL) {
        const c_ = pw.charAt(idx);
        const c_idx = _arr1_.indexOf(c_);
        const c_two_d = c_idx > 9;
        const c_str = `${c_idx}`;
        let sc_idx = c_idx + _kshift;
        const sc_max = sc_idx >= _arr1L_;

        if (sc_max) {
          sc_idx = sc_idx - _arr1L_;
        }

        valueArr.push(_arr1_[sc_idx]);
        valueArr.push(_arr2_[`${!c_two_d ? 0 : c_str.charAt(0)}`]);
        valueArr.push(_arr2_[`${c_str.charAt(!c_two_d ? 0 : 1)}`]);

        idx += 1;
      }

      valueArr.push(`${_kshift}`);

      return { err: false, value: valueArr.join('') };
    } else return { err: true, value: '' };
  }

  onLoginResult(fn) {
    return this.resultFromLoginOBS.subscribe({ next: fn });
  }

  onSignupResult(fn) {
    return this.resultFromSignupOBS.subscribe({ next: fn });
  }

  onProfileLoaded(fn) {
    if (this.profile_context) {
      return this.profile_context.userProfileLoadedOBS.subscribe({ next: fn });
    }

    return null;
  }

  onInspectTaskResult(fn) {
    return this.resultFromInspectTaskOBS.subscribe({ next: fn });
  }

  onInspectTaskPWResult(fn) {
    return this.resultFromInspectTaskPWOBS.subscribe({ next: fn });
  }

  isLoggedIn() {
    return (
      this.profile_context &&
      this.profile_context.state &&
      this.profile_context.state.isLoggedIn
    );
  }

  async sendSignup(
    rawUsername,
    rawPassword,
    email,
    firstName,
    lastName,
    invite = ''
  ) {
    const x = this.profile_context.encVAL.signup.id.split('-');
    const arr1 = this.profile_context.encVAL.signup.k1;
    const arr2p = x[0];
    const arr2u = x[x.length - 1];
    const uRES = this.enc(rawUsername, arr1, arr2u);
    const pRES = this.enc(rawPassword, arr1, arr2p);

    let success = false;
    let try_again = false;
    let email_taken = false;
    let username_taken = false;
    let respond = {};

    if (!uRES.err && !pRES.err) {
      const eUsername = uRES.value;
      const ePassword = pRES.value;

      const res = await axios({
        method: 'POST',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        data: {
          username: eUsername,
          password: ePassword,
          email,
          firstName,
          lastName,
          invite
        },
        url: '/v1/user/sign-up',
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res.data) {
        respond = { ...res.data };
      }

      if (res && res.status === 201 && res.data && res.data.success) {
        success = true;
      } else if (res.data && res.data.try_again) {
        try_again = true;
      } else if (res.data && res.data.email_taken) {
        email_taken = true;
      } else if (res.data && res.data.username_taken) {
        username_taken = true;
      } else if (res.data && res.data.invalids) {
        success = false;
      }
    }

    this.resultFromSignupOBS.next({
      success,
      try_again,
      email_taken,

      username_taken,
      respond
    });
  }

  async sendLogin(rawUsername, rawPassword, isEmail = false) {
    /**
     * We need to encrypt username and password
     */

    const x = this.profile_context.encVAL.login.id.split('-');
    const arr1 = this.profile_context.encVAL.login.k1;
    const arr2p = x[0];
    const arr2u = x[x.length - 1];
    let uRES = { err: true };
    let pRES = { err: true };

    if (!isEmail) {
      uRES = this.enc(rawUsername, arr1, arr2u);
    }

    pRES = this.enc(rawPassword, arr1, arr2p);
    let success = false;
    let respond = {};
    let notApproved = false;

    if ((!uRES.err || isEmail) && !pRES.err) {
      const eUsername = isEmail ? rawUsername : uRES.value;
      const ePassword = pRES.value;

      try {
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          data: {
            username: eUsername,
            password: ePassword
          },
          url: '/v1/user/login',
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data && res.data.success) {
          success = true;
          respond = res.data;
        } else if (
          res.data &&
          typeof res.data.approved === 'boolean' &&
          !res.data.approved
        ) {
          respond = res.data;
          notApproved = true;
        }
      } catch (err) {
        Logger.log(`Failed to login user: ${err.message}`);
      }

      this.resultFromLoginOBS.next({ success, respond, notApproved });
    }
  }

  async sendInpsectTaskPW(task_id = '', u_ref = '', passcode = '') {
    if (this.profile_context) {
      try {
        if (this.VARS.gettingTaskInfo) {
          return;
        } else {
          this.VARS.gettingTaskInfo = true;
          task_id = `${task_id}`.toLowerCase();
        }

        const { user, isLoggedIn } = this.profile_context.state;
        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'PUT',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          ...(isLoggedIn && {
            headers: {
              authorization: `BEARER ${token}`,
              'x-custom-user-session': `${username_ref}`
            }
          }),
          data: {
            passcode
          },
          url: `/v1/pub/i/task/pw?task_id=${task_id}&u_ref=${u_ref}${
            isLoggedIn ? `&auth_token_id=${id}&lb=${lb}&n=${n}` : ''
          }`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data && res.data.success) {
          this.resultFromInspectTaskPWOBS.next({
            success: true,
            respond: { ...res.data }
          });
        } else if (
          res.status === 401 ||
          res.status === 404 ||
          (res.data && res.data.removed)
        ) {
          if (ProfileAPI) return await ProfileAPI.sendLogout();
        } else {
          this.resultFromInspectTaskPWOBS.next({ success: false });
        }

        this.VARS.gettingTaskInfo = false;
      } catch (err) {
        this.VARS.gettingTaskInfo = false;
        this.resultFromInspectTaskPWOBS.next({ success: false });
      }
    }
  }

  async sendInspectTask(task_id = '', u_ref = '') {
    if (this.profile_context) {
      try {
        if (this.VARS.gettingTaskInfo) {
          return this.VARS.inspectTaskQueue.push({ task_id, u_ref });
        } else {
          this.VARS.gettingTaskInfo = true;
        }

        const { user, isLoggedIn } = this.profile_context.state;
        const { token, id, lb, n, username_ref } = user.auth;
        const taskIdLowerCaps = task_id.toLowerCase();
        const res = await axios({
          method: 'GET',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          ...(isLoggedIn && {
            headers: {
              authorization: `BEARER ${token}`,
              'x-custom-user-session': `${username_ref}`
            }
          }),
          url: `/v1/pub/i/task?task_id=${taskIdLowerCaps}&u_ref=${u_ref}${
            isLoggedIn ? `&auth_token_id=${id}&lb=${lb}&n=${n}` : ''
          }`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res.status === 200 && res.data && res.data.success) {
          this.VARS.inspectTaskMap[task_id] = res.data;
          this.resultFromInspectTaskOBS.next({
            respond: this.VARS.inspectTaskMap[`${task_id}`],
            success: true
          });

          ProfileAPI.TASKS.cache(taskIdLowerCaps, u_ref, res.data);
        } else if (res.data && res.data.not_exists) {
          this.resultFromInspectTaskOBS.next({
            not_exists: true,
            success: false
          });
        } else if (res.data && res.data.withPW) {
          this.resultFromInspectTaskOBS.next({
            requiredPW: true,
            success: false
          });
        } else if (
          res.status === 401 ||
          res.status === 404 ||
          (res.data && res.data.removed)
        ) {
          if (ProfileAPI) return await ProfileAPI.sendLogout();
        } else {
          this.resultFromInspectTaskOBS.next({
            not_exists: true,
            success: false
          });
        }

        this.VARS.gettingTaskInfo = false;
        return this.checkInspectTaskQ();
      } catch (err) {
        Logger.log(`Failed to fetch data for task inspect: ${err.message}`);
      }
    }
  }

  async sendEarlyAccess(email = '') {
    if (this.profile_context) {
      try {
        const res = await axios({
          method: 'PUT',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          data: {
            email
          },
          url: `/v1/pub/early-access`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          return res.data;
        } else {
          return null;
        }
      } catch (err) {
        Logger.log(`Failed to submit email for early access`);
        return null;
      }
    }
  }

  checkInspectTaskQ() {
    const { inspectTaskQueue } = this.VARS;

    if (inspectTaskQueue.length > 0) {
      const { task_id, u_ref } = this.VARS.inspectTaskQueue.splice(0, 1)[0];
      Logger.log(`Checked queue and now inspecting task: ${task_id}`);
      return this.sendInspectTask(task_id, u_ref);
    }
  }

  onHideOrShowSearch(fn) {
    if (this.onHideSearchbarOBS) {
      return this.onHideSearchbarOBS.subscribe({ next: fn });
    }

    return null;
  }

  hideSearchbar() {
    this.VARS.hideSearch = true;
    this.onHideSearchbarOBS.next();
  }

  showSearchbar() {
    this.VARS.hideSearch = false;
    this.onHideSearchbarOBS.next();
  }

  async getECResetPW() {
    if (!this.profile_context) {
      return;
    }

    try {
      const resECRPW = await axios({
        method: 'GET',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        url: `/v1/pub/scr/client-reset-pw?uuid=${v4()}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (
        resECRPW &&
        resECRPW.status === 200 &&
        resECRPW.data &&
        resECRPW.data.valid
      ) {
        this.profile_context.encVAL['resetpw'] = {
          err: false,
          ...resECRPW.data.v
        };
      } else {
        Logger.log(`Failed to get user security for login`);
      }
    } catch (err) {
      Logger.log(`Failed to get user security for reset pw: ${err.message}`);
    }
  }

  async getECPWOnly() {
    if (!this.profile_context) {
      return;
    }

    try {
      const resECRPW = await axios({
        method: 'GET',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        url: `/v1/pub/scr/client-pw-only?uuid=${v4()}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (
        resECRPW &&
        resECRPW.status === 200 &&
        resECRPW.data &&
        resECRPW.data.valid
      ) {
        this.profile_context.encVAL['pwonly'] = {
          err: false,
          ...resECRPW.data.v
        };
      } else {
        Logger.log(`Failed to get user security for login`);
      }
    } catch (err) {
      Logger.log(`Failed to get user security for reset pw: ${err.message}`);
    }
  }

  onSubmitEmailForgotPW(fn) {
    if (this.resultsFromSubmitForgotPW) {
      return this.resultsFromSubmitForgotPW.subscribe({ next: fn });
    }

    return null;
  }

  async submitForgotPW(email = '') {
    if (!this.profile_context) {
      return;
    }

    try {
      const resForgotPW = await axios({
        method: 'PUT',
        baseURL:
          k.WEB_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        data: {
          email,
          env:
            process.env.NODE_ENV === 'development'
              ? 'development'
              : 'production'
        },
        url: `/_m/resetpw`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (resForgotPW && resForgotPW.data && resForgotPW.data.valid) {
        this.resultsFromSubmitForgotPW.next({ success: true });
      }

      this.resultsFromSubmitForgotPW.next({ success: false });
    } catch (err) {
      Logger.log(`Failed to submit for forgot PW: ${err.message}`);
      this.resultsFromSubmitForgotPW.next({ success: false });
    }
  }

  onSubmitVerifyResetPW(fn) {
    if (this.resultsFromVerifyResetPWToken) {
      return this.resultsFromVerifyResetPWToken.subscribe({ next: fn });
    }

    return null;
  }

  async submitVerifyResetPW(token = '') {
    if (!this.profile_context) {
      return;
    }

    try {
      const resVerifyResetPW = await axios({
        method: 'GET',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        url: `/v1/pub/verify/reset-pw?token=${token}`,
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (
        resVerifyResetPW &&
        resVerifyResetPW.status === 200 &&
        resVerifyResetPW.data &&
        resVerifyResetPW.data.valid
      ) {
        this.resultsFromVerifyResetPWToken.next({ valid: true });
      } else {
        this.resultsFromVerifyResetPWToken.next({ valid: false });
      }
    } catch (err) {
      Logger.log(`Failed to verify code + token for reset PW: ${err.message}`);
      this.resultsFromVerifyResetPWToken.next({ valid: false });
    }
  }

  onResultsResetPW(fn) {
    if (this.resultsFromResetPWOBS) {
      return this.resultsFromResetPWOBS.subscribe({ next: fn });
    }
    return null;
  }

  async sendResetPW(username, password, code, token) {
    if (!this.profile_context) {
      return;
    }

    try {
      const x = this.profile_context.encVAL.resetpw.id.split('-');
      const arr1 = this.profile_context.encVAL.resetpw.k1;
      const arr2p = x[0];
      const arr2u = x[x.length - 1];
      const uRES = this.enc(username, arr1, arr2u);
      const pRES = this.enc(password, arr1, arr2p);

      if (uRES.err || pRES.err) {
        return this.resultsFromResetPWOBS.next({ success: false });
      }

      const usernameEnc = uRES.value;
      const passwordEnc = pRES.value;
      const resConfirmResetPW = await axios({
        method: 'PUT',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        data: {
          username: usernameEnc,
          new_password: passwordEnc
        },
        url: `/v1/pub/reset-pw?token=${token}&code=${code}`
      });

      if (
        resConfirmResetPW &&
        resConfirmResetPW.status === 200 &&
        resConfirmResetPW.data &&
        resConfirmResetPW.data.success
      ) {
        this.resultsFromResetPWOBS.next({ success: true });
      } else {
        this.resultsFromResetPWOBS.next({ success: false });
      }
    } catch (err) {
      Logger.log(`Failed to confirm reset PW: ${err.message}`);
      this.resultsFromResetPWOBS.next({ success: false });
    }
  }

  async sendRedeemBetaInvite(
    rawUsername = '',
    rawPassword = '',
    lb = '',
    n = -1,
    code = ''
  ) {
    if (
      !this.profile_context ||
      !rawUsername ||
      !rawPassword ||
      !lb ||
      typeof n !== 'number' ||
      !code
    ) {
      return null;
    }

    try {
      const x = this.profile_context.encVAL.login.id.split('-');
      const arr1 = this.profile_context.encVAL.login.k1;
      const arr2p = x[0];
      const arr2u = x[x.length - 1];
      const uRES = this.enc(rawUsername, arr1, arr2u);
      const pRES = this.enc(rawPassword, arr1, arr2p);

      if (!uRES.err && !pRES.err) {
        const eUsername = uRES.value;
        const ePassword = pRES.value;

        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          data: {
            username: eUsername,
            password: ePassword,
            code,
            lb,
            n
          },
          url: '/v1/pub/redeem-beta',
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          return res.data;
        } else {
          return null;
        }
      }
    } catch (err) {
      Logger.log(`Failed to redeem beta invite code: ${err.message}`);
      return null;
    }
  }

  async sendRedeemSignupInvite(code = '') {
    const result = { err: true, invite: '' };
    try {
      const res = await axios({
        method: 'POST',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        data: {
          code
        },
        url: '/v1/pub/redeem-signup',
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data) {
        if (res.data.valid) {
          result.err = false;
          result.invite = res.data.invite;
        }
      }
    } catch (err) {
      Logger.log(
        `Failed to redeem signup code: ${code} w error: ${err.message}`
      );
    }

    return result;
  }

  async getSiteInfo(url = '') {
    const result = { err: true, ogs: {} };

    try {
      if (!url) {
        return result;
      }

      const res = await axios({
        method: 'GET',
        baseURL:
          k.API_DASHBOARD_URLS[
            Store.CONFIGS.IS_DEV ? 'development' : 'production'
          ],
        url: '/v1/pub/i/site' + (url ? `?url=${url}` : ''),
        validateStatus: function (status) {
          return status >= 200 && status <= 500;
        }
      });

      if (res && res.data) {
        if (res.data.valid) {
          result.err = false;
          result.ogs = res.data.ogs;
        }
      }
    } catch (err) {
      Logger.log(`Failed to fetch site info for url: ${url} err:`, err.message);
    }

    return result;
  }
}

export default Public;
