import React from 'react';
import cx from 'classnames';
import styles from './LogoLoad.scss';
import AppLogo from 'src/components/app-logo';

const LogoLoad = props => {
  return (
    <div className={cx(styles.logo_load, styles.flex_column_xy)}>
      <AppLogo />
      <div
        className={cx({ [styles.hide_element]: !props.message }, styles.msg)}
      >
        <h3>{props.message}</h3>
      </div>
    </div>
  );
};

export default LogoLoad;
