import React from 'react';
import cx from 'classnames';
import styles from './commons.scss';

export const AdjustableAbsolutWrap = props => {
  return (
    <div
      style={{
        padding: `${
          typeof props.paddingBottom === 'number' && props.paddingBottom
            ? props.paddingBottom
            : 0
        }px`
      }}
      className={cx(styles.adjustable_absolute)}
    >
      {props.children}
    </div>
  );
};
