import React from 'react';
import cx from 'classnames';
import styles from './AppLogo.scss';

const AppLogo = props => {
  return (
    <div
      className={cx(styles.flex_row_xy, styles.app_logo, {
        [props.className]: !!props.className
      })}
    >
      <div className={cx(styles.flex_row_xy, styles.fav_icon)}>
        <div className={styles.logo}> </div>
      </div>
      <p className={styles.word}>{'chamu'}</p>
      <div
        className={cx(styles.beta_title, {
          [styles.hide_element]: !props.showBeta
        })}
      >
        <h5>{'Beta'}</h5>
      </div>
    </div>
  );
};

export default AppLogo;
