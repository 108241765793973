import React, { useEffect } from 'react';
import cx from 'classnames';
import i18n, { Markdown } from 'src/locales';
import styles from './TermsOfService.scss';

const TermsOfService = props => {
  useEffect(() => {
    try {
      window.scrollTo(0, 0);
    } catch (err) {}
  }, []);

  return (
    <div className={styles.terms_of_service_wrap}>
      <div className={styles.label}>
        <h2>{i18n('legal_terms_service_title')}</h2>
      </div>
      <div className={styles.date_updated}>
        {Markdown('legal_last_updated_date', {
          date1: 'April 12, 2021 9:00AM PHT'
        })}
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_terms_service_intro_one')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_terms_service_registration')}</h3>
      </div>
      <div
        style={{ marginTop: '10px' }}
        className={cx(styles.para, styles.reg_mark)}
      >
        {Markdown('legal_terms_service_registration_one', {
          mark1: null,
          url1: { link: '/docs/privacy-policy' },
          mark2: null,
          mark3: null
        })}
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_terms_service_general_payment')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_terms_service_general_payment_one')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_terms_service_general_price')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_terms_service_general_price_one')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_terms_service_authorization')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_terms_service_authorization_one')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_terms_service_subscription_service')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_terms_service_subscription_service_one')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_terms_service_del_account')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_terms_service_del_account_one')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_terms_service_disclaimer')}</h3>
      </div>
      <ul className={styles.points}>
        <li>{i18n('legal_terms_service_disclaimer_point_one')}</li>
        <li>{i18n('legal_terms_service_disclaimer_point_two')}</li>
      </ul>
      <div className={styles.label_section}>
        <h3>{i18n('legal_terms_service_mods')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_terms_service_mods_one')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_terms_service_governing')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_terms_service_governing_one')}</p>
      </div>
    </div>
  );
};

export default TermsOfService;
