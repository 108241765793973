import Store from 'src/lib/store';
import axios from 'axios';
import ProfileAPI from 'src/profile-manager/API';
import AppAPI from './API';
import Logger from 'src/lib/Logger';
import { fromEvent } from 'rxjs';

class Flow {
  static app_context = null;
  static localOnBlurSubscriber = null;
  static localOnFocusSubscriber = null;

  constructor(ctx) {
    this.app_context = ctx;
    this.checkSettings = this.checkSettings.bind(this);
  }

  async start() {
    await Store.start();

    /**
     * Set localConfigs from storage to app-manager state
     */

    await this.app_context.setStateAsync({
      localConfigs: {
        ...this.app_context.state.localConfigs,
        ...Store.CONFIGS.app
      }
    });
    await this.checkLocalConfigs();
    await this.checkSettings();
    this.setListeners();

    if (window.location.pathname.indexOf('/sign-up') === 0) {
      await this.probe();
    } else {
      this.probe();
    }

    await this.app_context.confirmLoaded();
  }

  setListeners() {
    this.localOnBlurSubscriber = fromEvent(window, 'blur').subscribe(
      this.onBlur.bind(this)
    );

    this.localOnFocusSubscriber = fromEvent(window, 'focus').subscribe(
      this.onFocus.bind(this)
    );
  }

  probe() {
    return new Promise(async resolve => {
      try {
        const res = await axios({
          method: 'PUT',
          baseURL:
            'https://us-central1-chamu-cloud.cloudfunctions.net/UserPublic',
          url: `/_p/probe`,
          headers: {
            'Access-Control-Allow-Origin': '*'
          },
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data) {
          const dataJSON =
            typeof res.data !== 'object' ? JSON.parse(res.data) : res.data;
          const [country, region, ...IPs] = dataJSON.l.split(',');

          localStorage.removeItem('__devi_meta__');
          localStorage.setItem('__devi_meta__', `c:${country},r:${region}`);
          await AppAPI.setGlobalConfig('country', country);
          await AppAPI.setGlobalConfig('region', region);
          await AppAPI.setGlobalConfig('IPs', [...IPs]);
          await AppAPI.setGlobalConfig('successProbe', true);
        }
      } catch (err) {
        console.error(err);
        await this.checkForStoredDeviceMeta();
      }

      return resolve();
    });
  }

  async checkForStoredDeviceMeta() {
    if (Store.CONFIGS.HAS_LOCAL_STORAGE_AVAILABLE) {
      try {
        const deviceMetaSTR = localStorage.getItem('__devi_meta__');

        if (typeof deviceMetaSTR && `${deviceMetaSTR}`.length > 0) {
          const [c, r] = deviceMetaSTR.split(',');
          const country = `${c}`.split('c:')[1];
          const region = `${r}`.split('r:')[1];
          const { globalConfigs } = this.app_context.state;

          globalConfigs.country = country;
          globalConfigs.region = region;
          await this.app_context.setStateAsync({ globalConfigs });
        }
      } catch (err) {}
    }
  }

  async checkLocalConfigs() {
    if (this.app_context) {
      const { localConfigs } = this.app_context.state;

      if (localConfigs) {
        if (!Array.isArray(localConfigs.recentSearch)) {
          const recentSearch = [];
          localConfigs.recentSearch = recentSearch;
          Store.CONFIGS.app['recentSearch'] = recentSearch;
          await this.app_context.setStateAsync({ localConfigs });
        } else if (!Store.CONFIGS.app['recentSearch']) {
          Store.CONFIGS.app['recentSearch'] = localConfigs.recentSearch;
        }

        if (localConfigs.tasks) {
          const reset =
            !localConfigs.tasks.selectedTags ||
            !Array.isArray(localConfigs.tasks.selectedTags) ||
            localConfigs.tasks.selectedTags.filter(t => typeof t !== 'string')
              .length;

          if (reset) {
            const tasks = localConfigs.tasks;

            tasks.selectedTags = [];
            if (!tasks) {
              await AppAPI.setLocalConfigs('tasks', tasks);
            }
          }
        } else {
          await AppAPI.setLocalConfigs('tasks', {
            selectedTags: [],
            selectedState: []
          });
        }
      }
    }
  }

  async checkSettings() {
    if (this.app_context) {
      if (
        window &&
        window.matchMedia &&
        typeof window.matchMedia === 'function' &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        Logger.log(`User is dark mode`);

        if (!AppAPI.getLocalConfig('darkMode')) {
          await AppAPI.setLocalConfigs('darkMode', true);
        }
      } else {
        Logger.log(`User is not dark mode`);

        if (AppAPI.getLocalConfig('darkMode')) {
          await AppAPI.setLocalConfigs('darkMode', false);
        }
      }

      if (
        window &&
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener
      ) {
        /**
         * Listen to changes
         */

        Logger.log(`Listening to user theme changes`);
        window
          .matchMedia('(prefers-color-scheme: dark)')
          .addEventListener('change', this.checkSettings);
      }
    }
  }

  onBlur() {
    Logger.log(`User on blur`);
    if (this.app_context) {
      Store.CONFIGS.onBlur = true;

      AppAPI.focusChanged();
    }
  }

  onFocus() {
    Logger.log(`User on focus`);

    if (this.app_context) {
      Store.CONFIGS.onBlur = false;

      if (ProfileAPI.USER_DATA.checkIsLoggedIn()) {
        Store.OS.checkConnectivity();
        Store.OS.userConnectivityWatch();
      }

      AppAPI.focusChanged();
    }
  }
}

export default Flow;
