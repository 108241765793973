import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './CommonButton.scss';
import { fromEvent } from 'rxjs';

const CommonButton = props => {
  const localButtonRef = useRef(null);

  const onLocalClick = target => {
    if (typeof props.onClick === 'function' && !props.disabled) {
      props.onClick(target);
    }
  };

  useEffect(() => {
    let localButtonClickSubscriber = null;
    if (localButtonRef && localButtonRef.current) {
      if (localButtonClickSubscriber) {
        localButtonClickSubscriber.unsubscribe();
      }

      localButtonClickSubscriber = fromEvent(
        localButtonRef.current,
        'click'
      ).subscribe(onLocalClick);
    }

    return () => {
      if (localButtonClickSubscriber) {
        localButtonClickSubscriber.unsubscribe();
      }
    };

    // eslint-disable-next-line
  }, [localButtonRef, localButtonRef.current, props.disabled]);

  return (
    <div
      className={cx(
        {
          [props.className]: !!props.className,
          [styles.common_button_no_disabled]: !props.disabled
        },
        styles.common_button
      )}
      ref={localButtonRef}
    >
      <button>
        <p>{props.text}</p>
      </button>
      <div
        className={cx(
          { [styles.common_button_disabled]: props.disabled },
          styles.cover
        )}
      ></div>
    </div>
  );
};

export default CommonButton;
