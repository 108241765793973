import React, { useEffect } from 'react';
import cx from 'classnames';
import styles from './PrivacyPolicy.scss';
import i18n, { Markdown } from 'src/locales';

const PrivacyPolicy = () => {
  useEffect(() => {
    try {
      window.scrollTo(0, 0);
    } catch (err) {}
  }, []);

  return (
    <div className={styles.privacy_policy_wrap}>
      <div className={styles.label}>
        <h2>{i18n('legal_privacy_title')}</h2>
      </div>
      <div className={styles.date_updated}>
        {Markdown('legal_last_updated_date', {
          date1: 'April 12, 2021 9:00AM PHT'
        })}
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_privacy_intro')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_intro_one')}</p>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_intro_two')}</p>
      </div>
      <div
        style={{ marginTop: '10px' }}
        className={cx(styles.intro_not_agree, styles.para)}
      >
        <p> {i18n('legal_privacy_intro_three')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_privacy_collection_info')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_collection_info_one')}</p>
      </div>
      <div className={styles.label_sub_one}>
        <h4>{i18n('legal_privacy_collection_info_logging')}</h4>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_collection_info_logging_one')}</p>
      </div>
      <div className={styles.label_sub_one}>
        <h4>{i18n('legal_privacy_collection_info_personal')}</h4>
      </div>
      <div className={styles.para}>
        <p>{i18n('legal_privacy_collection_info_personal_one')}</p>
      </div>
      <ul className={styles.points}>
        <li>{i18n('legal_privacy_collection_info_personal_name')}</li>
        <li> {i18n('legal_privacy_collection_info_personal_email')} </li>
      </ul>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_collection_info_personal_two')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_privacy_access')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_access_one')}</p>
      </div>
      <ul className={styles.points}>
        <li>{i18n('legal_privacy_access_point1')}</li>
        <li>{i18n('legal_privacy_access_point2')}</li>
        <li>{i18n('legal_privacy_access_point3')}</li>
        <li>{i18n('legal_privacy_access_point4')}</li>
        <li>{i18n('legal_privacy_access_point5')}</li>
        <li>{i18n('legal_privacy_access_point6')}</li>
        <li>{i18n('legal_privacy_access_point7')}</li>
      </ul>
      <div className={styles.label_section}>
        <h3>{i18n('legal_privacy_security_info')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_security_info_one')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_privacy_disclosure')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_disclosure_one')}</p>
      </div>
      <ul className={styles.points}>
        <li>{i18n('legal_privacy_disclosure_point1')}</li>
        <li>{i18n('legal_privacy_disclosure_point2')}</li>
        <li>{i18n('legal_privacy_disclosure_point3')}</li>
        <li>{i18n('legal_privacy_disclosure_point4')}</li>
        <li>{i18n('legal_privacy_disclosure_point5')}</li>
      </ul>
      <div className={styles.label_section}>
        <h3>{i18n('legal_privacy_your_rights')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_your_rights_one')}</p>
      </div>
      <ul className={styles.points}>
        <li>{i18n('legal_privacy_your_rights_point1')}</li>
        <li>{i18n('legal_privacy_your_rights_point2')}</li>
        <li>{i18n('legal_privacy_your_rights_point3')}</li>
        <li>{i18n('legal_privacy_your_rights_point4')}</li>
        <li>{i18n('legal_privacy_your_rights_point5')}</li>
      </ul>
      <div className={styles.label_section}>
        <h3>{i18n('legal_privacy_cookies')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_cookies_one')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_privacy_changes_to_policy')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_changes_to_policy_one')}</p>
      </div>
      <div className={styles.label_section}>
        <h3>{i18n('legal_privacy_contact_us')}</h3>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.para}>
        <p>{i18n('legal_privacy_contact_us_one')}</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
