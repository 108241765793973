import axios from 'axios';
import k from 'src/constants/k';
import Logger from 'src/lib/Logger';
import Store from 'src/lib/store';
import { Subject } from 'rxjs';

class Spaces {
  profile_context = null;
  spacesPage = 1;
  spaces = [];
  spacesId = [];
  spacesInKeys = {};
  accumulatedSpacesInpage = {};
  totalSpaces = 0;
  totalPerPage = 20;
  initFetch = false;
  onSpacesListOBS = null;

  constructor() {
    this.onSpacesListOBS = new Subject();
  }

  setContext(profile_context) {
    this.profile_context = profile_context;
  }

  doneWithInitialFetch() {
    return this.initFetch;
  }

  isUserPremiumWithSpace() {
    return Boolean(
      this.profile_context &&
        this.profile_context.state &&
        this.profile_context.state.user &&
        this.profile_context.state.user.plan &&
        this.profile_context.state.user.plan.isPremiumTeams &&
        !this.profile_context.state.user.plan.isExpired
    );
  }

  onSpacesList(fn) {
    if (this.onSpacesListOBS && typeof fn === 'function') {
      return this.onSpacesListOBS.subscribe({ next: fn });
    }

    return null;
  }

  idExists(id = '') {
    return this.spacesId.includes(id);
  }

  getSpaceById(id = '') {
    for (let i = 0; i < this.spaces.length; i++) {
      const space = this.spaces[i];

      if (space && space.id === id) {
        return space;
      }
    }

    return null;
  }

  async getJoinedSpaces() {
    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;

        if (!isLoggedIn || !this.isUserPremiumWithSpace()) {
          Logger.log(
            `Tried fetching space but not logged in or subscription plan is incorrect`
          );

          return [];
        }

        if (!this.initFetch) {
          this.initFetch = true;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'GET',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          url: `/v1/spaces/joined-list?auth_token_id=${id}&lb=${lb}&n=${n}&p=${this.spacesPage}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res) {
          if (res.data && res.data.valid) {
            this.accumulatedSpacesInpage[`${this.spacesPage}`] =
              res.data.spaces;

            if (!this.totalSpaces) {
              this.totalSpaces = res.data.total || 0;
            }

            if (res.data.perPage) {
              this.totalPerPage = res.data.perPage;
            }

            for (let i = 0; i < res.data.spaces.length; i++) {
              const space = res.data.spaces[i];

              if (space) {
                const existsInSpacesId = this.spacesId.indexOf(space.space_id);

                if (
                  existsInSpacesId < 0 ||
                  (existsInSpacesId >= 0 &&
                    this.spacesInKeys[space.space_id] &&
                    (this.spacesInKeys[space.space_id].name !== space.name ||
                      this.spacesInKeys[space.space_id].bio !== space.bio ||
                      (space.avatar &&
                        this.spacesInKeys[space.space_id].avatar !==
                          space.avatar)))
                ) {
                  if (existsInSpacesId < 0) {
                    this.spaces.push(space);
                  } else {
                    // just update information
                    this.spaces[existsInSpacesId] = space;
                  }

                  if (existsInSpacesId < 0) {
                    this.spacesId.push(space.space_id);
                  }

                  this.spacesInKeys[space.space_id] = space;
                }
              }
            }

            if (this.totalSpaces === this.spacesId.length) {
              this.onSpacesListOBS.next({
                spaces: this.spaces
              });
              await this.getJoinedSpacesInfo();
            } else {
              this.spacesPage += 1;
              this.getJoinedSpaces();
            }

            return this.spaces;
          } else if (res.data.message) {
            throw new Error(res.data.message);
          } else {
            throw new Error(
              `Wrong with status: ${res.status} and statusText: ${res.statusText}`
            );
          }
        } else return [];
      } catch (err) {
        Logger.log(
          `Something went wrong when fetching user's joined spaces list: ${err.message}`
        );

        return [];
      }
    } else {
      return [];
    }
  }

  async create(name = '') {
    const result = {
      err: true,
      try_again: false,
      taken: false,
      message: '',
      not_allowed_plan: false
    };

    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      }

      const { isLoggedIn, user } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (user) {
        // @todo finish endpoint for creating a new space

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            name
          },
          url: `/v1/spaces/create?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          result.err = false;
          this.totalSpaces += 1;
          this.spacesPage = 1;
          await this.getJoinedSpaces();
        } else if (res) {
          result.try_again = res.data && res.data.try_again;
          result.taken = res.data && res.data.name_taken;
          result.not_allowed_plan = res.data && res.data.not_allowed_plan;
        }
      }
    } catch (err) {
      result.err = true;
      if (err) {
        result.message = err.message;

        Logger.log(
          `Something went wrong when creating new space: ${name} due error: ${err.message}`
        );
      }
    }

    return result;
  }

  async edit(props = {}, space_id = '', ref_id = '') {
    const result = {
      err: true,
      try_again: false,
      message: '',
      not_allowed_plan: false
    };

    try {
      if (!props) {
        throw new Error('Invalid props');
      }

      if (!this.profile_context) {
        throw new Error('No profile context');
      }

      const { isLoggedIn, user } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (user) {
        // @todo finish endpoint for creating a new space
        const editingName = typeof props.name === 'string';
        const editingBio = typeof props.bio === 'string';

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'PUT',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            ref_id,
            id: space_id,
            ...(editingName && { name: props.name }),
            ...(editingBio && { bio: props.bio })
          },

          url: `/v1/spaces/edit?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          const spaceInfo = this.getSpaceById(space_id);
          if (editingName) {
            spaceInfo.name = props.name;
          }

          if (editingBio) {
            spaceInfo.bio = props.bio;
          }

          if (editingBio || editingName) {
            this.onSpacesListOBS.next({
              spaces: this.spaces
            });
          }

          result.err = false;
        } else if (res && res.data && res.data.message) {
          throw new Error(res.data.message);
        }
      }
    } catch (err) {
      result.err = true;
      if (err) {
        result.message = err.message;

        Logger.log(
          `Something went wrong when editing space: ${space_id} due error: ${err.message}`
        );
      }
    }

    return result;
  }

  get() {
    return this.spaces;
  }

  async getJoinedSpacesInfo() {
    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      } else {
        Logger.log(`Getting joined spaces info`);
      }

      const { isLoggedIn, user } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (user) {
        for (let i = 0; i < this.spaces.length; i++) {
          const space = this.spaces[i];

          if (space) {
            const res = await this.getInfo(space.space_id, space.ref_id);

            if (res && !res.err && res.info) {
              this.spaces[i] = { ...space, ...res.info, ref_id: space.ref_id };
            }
          }
        }
      }
    } catch (err) {
      if (err) {
        Logger.log(
          `Failed to fetch all space info in list error:`,
          err.message
        );
      }
    }
  }

  async getInfo(space_id = '', ref_id = '') {
    const result = { err: true, info: null };
    try {
      if (!this.profile_context) {
        throw new Error('No profile context');
      }

      const { isLoggedIn, user } = this.profile_context.state;

      if (!isLoggedIn) {
        throw new Error('Not logged in');
      } else if (user) {
        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            ref_id,
            id: space_id
          },
          url: `/v1/spaces/info?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.valid && res.data.space) {
          result.err = false;
          result.info = res.data.space;
        } else if (res) {
          throw new Error(
            `status: ${res.status} statusText: ${res.statusText} err: ${
              res.data.message || 'n/a'
            }`
          );
        }
      }
    } catch (err) {
      Logger.log(`Failed to get info for space with id: ${space_id}`);
    }

    return result;
  }
}

export default Spaces;
