import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styles from './Docs.scss';
import AppLogoIcon from 'src/components/app-logo-icon';
import Intro from './intro';
import About from './about';
import Formats from './formats';
import Tasks from './tasks';
import PrivacyPolicy from 'src/pages/legal/privacy-policy';
import TermsOfService from 'src/pages/legal/terms-of-service';
import ProfileAPI from 'src/profile-manager/API';
import ButtonClick from 'src/components/button-click';
import i18n, { URLMarkdown } from 'src/locales';
import { withUserProfileSettings } from 'src/profile-manager';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { withRouter, Switch, Route, Redirect, Link } from 'react-router-dom';

class Docs extends Component {
  headerActionREF = null;
  onResizeSubscriber = null;
  localUserInfoStatusChangeSubscriber = null;

  state = {
    activeDoc: 'default',
    mounted: false,
    notFound: false,
    navExpand: false,
    isLoggedIn: false
  };

  constructor() {
    super();

    this.onResize = this.onResize.bind(this);
    this.checkActiveDoc = this.checkActiveDoc.bind(this);
    this.headerActionREF = createRef();
    this.toggleNavExpand = this.toggleNavExpand.bind(this);
    this.onUserInfoStatusChange = this.onUserInfoStatusChange.bind(this);
  }

  componentDidMount() {
    this.onResizeSubscriber = fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(this.onResize);

    this.setState(
      {
        mounted: true
      },
      this.onMount.bind(this)
    );
  }

  componentWillUnmount() {
    this.headerActionREF = null;
    this.setState({ mounted: false });

    if (this.localUserInfoStatusChangeSubscriber) {
      this.localUserInfoStatusChangeSubscriber.unsubscribe();
    }

    if (this.onResizeSubscriber) {
      this.onResizeSubscriber.unsubscribe();
    }
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.location &&
      previousProps.location.pathname !== window.location.pathname &&
      window.location.pathname.indexOf('/docs') === 0
    ) {
      this.checkActiveDoc();
    }
  }

  onMount() {
    if (
      ProfileAPI.USER_DATA.checkIsFetchingInfo() ||
      (ProfileAPI.USER_DATA.info() && !ProfileAPI.USER_DATA.info().firstName)
    ) {
      this.localUserInfoStatusChangeSubscriber =
        ProfileAPI.USER_DATA.onProfileFetchingInfoStatus(
          this.onUserInfoStatusChange
        );
    } else {
      this.onUserInfoStatusChange();
    }

    this.checkActiveDoc();
  }

  onUserInfoStatusChange() {
    if (!this.state.mounted) {
      return;
    }

    const user = ProfileAPI.USER_DATA.info();

    if (user && user.firstName) {
      this.setState(
        {
          isLoggedIn: true
        },
        this.onResize
      );

      if (this.localUserInfoStatusChangeSubscriber) {
        this.localUserInfoStatusChangeSubscriber.unsubscribe();
        this.localUserInfoStatusChangeSubscriber = null;
      }
    }
  }

  checkActiveDoc() {
    const { pathname = '' } = window.location;
    const { activeDoc } = this.state;

    if (pathname === '/docs/about') {
      this.setState({
        activeDoc: 'about'
      });
    } else if (pathname === '/docs/tasks') {
      this.setState({
        activeDoc: 'tasks'
      });
    } else if (pathname === '/docs/privacy-policy') {
      this.setState({
        activeDoc: 'privacy_policy'
      });
    } else if (pathname === '/docs/terms-service') {
      this.setState({
        activeDoc: 'terms_service'
      });
    } else if (pathname === '/docs/formats') {
      this.setState({
        activeDoc: 'formats'
      });
    } else if (
      pathname === '/docs' ||
      pathname === '/docs/index' ||
      pathname === '/docs/main'
    ) {
      if (activeDoc !== 'default') {
        this.setState({
          activeDoc: 'default'
        });
      }
    } else {
      this.setState({ mounted: false, notFound: true });
    }

    this.onResize();
  }

  onResize() {
    const { mounted } = this.state;

    if (mounted && this.headerActionREF && this.headerActionREF.current) {
      const parent = this.headerActionREF.current.parentElement;

      if (parent && parent.children.length > 1) {
        const headerLogoREF = parent.children[0];
        const headerLabelREF = parent.children[1];
        const w1 = headerLogoREF.clientWidth;
        const w2 = headerLabelREF.clientWidth;
        const totalW = parent.clientWidth;
        const marginLeft =
          totalW - w1 - w2 - this.headerActionREF.current.clientWidth - 35;

        if (marginLeft > 0) {
          this.headerActionREF.current.style.marginLeft = `${marginLeft}px`;
        }
      }
    }
  }

  getNavTitle() {
    const { activeDoc } = this.state;

    if (activeDoc === 'default') {
      return i18n('docs_documentation_nav_intro_title');
    } else if (activeDoc === 'about') {
      return i18n('docs_documentation_nav_about_title');
    } else if (activeDoc === 'tasks') {
      return i18n('docs_documentation_tasks_title');
    } else if (activeDoc === 'privacy_policy') {
      return i18n('legal_privacy_title');
    } else if (activeDoc === 'terms_service') {
      return i18n('legal_terms_service_title');
    } else if (activeDoc === 'formats') {
      return i18n('docs_documentation_formats_title');
    } else {
      return '';
    }
  }

  toggleNavExpand() {
    const { navExpand, mounted } = this.state;

    if (mounted) {
      this.setState({
        navExpand: !navExpand
      });
    }
  }

  render() {
    const { activeDoc, notFound, navExpand, isLoggedIn, mounted } = this.state;
    let hasDP = false;
    let initials = '...';
    let dp = '';

    if (notFound) {
      return <Redirect to={'/not-found'} />;
    }

    if (isLoggedIn) {
      const user = ProfileAPI.USER_DATA.info();

      hasDP = !!(user && typeof user.dp === 'string' && user.dp);

      if (user.initials) {
        initials = user.initials;
      }

      if (hasDP) {
        dp = user.dp;
      }
    } else {
      if (mounted) {
        this.onResize();
      }
    }

    return (
      <div className={styles.docs}>
        <div className={styles.header}>
          <div>
            <div className={styles.logo_wrap}>
              <AppLogoIcon />
            </div>
            <div className={styles.header_title}>
              <h4>{i18n('docs_documentation_title')}</h4>
            </div>
            <div ref={this.headerActionREF} className={styles.header_action}>
              <div
                className={cx(styles.login, {
                  [styles.hide_element]: isLoggedIn
                })}
              >
                {URLMarkdown(
                  'common_signup_or_login',
                  {
                    url1: {
                      link: '/sign-up'
                    },
                    url2: {
                      link: '/login'
                    }
                  },
                  '',
                  false
                )}
              </div>
              <div
                className={cx(styles.user_home, {
                  [styles.hide_element]: !isLoggedIn
                })}
              >
                <div
                  style={{
                    ...(hasDP && {
                      background: `url(${dp})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    })
                  }}
                >
                  <h5 className={cx({ [styles.hide_element]: hasDP })}>
                    {initials}
                  </h5>
                  <div className={styles.cover}>
                    <Link to={'/user'}></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.actual}>
          <div>
            <div className={styles.nav}>
              <div>
                <ul>
                  <li
                    className={cx({
                      [styles.nav_inactive]: activeDoc !== 'default',
                      [styles.nav_active]: activeDoc === 'default'
                    })}
                  >
                    <div className={styles.dot}>{'\u2022'}</div>
                    <h4>{i18n('docs_documentation_nav_intro_title')}</h4>
                    <div className={styles.cover}>
                      <Link to={'/docs'}></Link>
                    </div>
                  </li>
                  <li
                    className={cx({
                      [styles.nav_inactive]: activeDoc !== 'about',
                      [styles.nav_active]: activeDoc === 'about'
                    })}
                  >
                    <div className={styles.dot}>{'\u2022'}</div>
                    <h4>{i18n('docs_documentation_nav_about_title')}</h4>
                    <div className={styles.cover}>
                      <Link to={'/docs/about'}></Link>
                    </div>
                  </li>
                  <li
                    className={cx({
                      [styles.nav_inactive]: activeDoc !== 'tasks',
                      [styles.nav_active]: activeDoc === 'tasks'
                    })}
                  >
                    <div className={styles.dot}>{'\u2022'}</div>
                    <h4>{i18n('docs_documentation_tasks_title')}</h4>
                    <div className={styles.cover}>
                      <Link to={'/docs/tasks'}></Link>
                    </div>
                  </li>
                  <li
                    className={cx({
                      [styles.nav_inactive]: activeDoc !== 'formats',
                      [styles.nav_active]: activeDoc === 'formats'
                    })}
                  >
                    <div className={styles.dot}>{'\u2022'}</div>
                    <h4>{i18n('docs_documentation_formats_title')}</h4>
                    <div className={styles.cover}>
                      <Link to={'/docs/formats'}></Link>
                    </div>
                  </li>
                  <li
                    className={cx({
                      [styles.nav_inactive]: activeDoc !== 'privacy_policy',
                      [styles.nav_active]: activeDoc === 'privacy_policy'
                    })}
                  >
                    <div className={styles.dot}>{'\u2022'}</div>
                    <h4>{i18n('legal_privacy_title')}</h4>
                    <div className={styles.cover}>
                      <Link to={'/docs/privacy-policy'}></Link>
                    </div>
                  </li>

                  <li
                    className={cx({
                      [styles.nav_inactive]: activeDoc !== 'terms_service',
                      [styles.nav_active]: activeDoc === 'terms_service'
                    })}
                  >
                    <div className={styles.dot}>{'\u2022'}</div>
                    <h4>{i18n('legal_terms_service_title')}</h4>
                    <div className={styles.cover}>
                      <Link to={'/docs/terms-service'}></Link>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <div>
                  <ButtonClick onClick={this.toggleNavExpand}>
                    <h4>{this.getNavTitle()}</h4>
                    <div
                      className={cx({
                        [styles.nav_mobile_arr_expand]: navExpand
                      })}
                    >
                      {' '}
                    </div>
                  </ButtonClick>
                </div>
                <div
                  className={cx({
                    [styles.nav_mobile_expand]: navExpand,
                    [styles.nav_mobile_noexpand]: !navExpand
                  })}
                >
                  <ul onClick={this.toggleNavExpand}>
                    <li className={styles.nav_active}>
                      <div className={styles.dot}>{'\u2022'}</div>
                      <h4>{i18n('docs_documentation_nav_intro_title')}</h4>
                      <div className={styles.cover}>
                        <Link to={'/docs'}></Link>
                      </div>
                    </li>
                    <li className={styles.nav_active}>
                      <div className={styles.dot}>{'\u2022'}</div>
                      <h4>{i18n('docs_documentation_nav_about_title')}</h4>
                      <div className={styles.cover}>
                        <Link to={'/docs/about'}></Link>
                      </div>
                    </li>
                    <li className={styles.nav_active}>
                      <div className={styles.dot}>{'\u2022'}</div>
                      <h4>{i18n('docs_documentation_tasks_title')}</h4>
                      <div className={styles.cover}>
                        <Link to={'/docs/tasks'}></Link>
                      </div>
                    </li>
                    <li className={styles.nav_active}>
                      <div className={styles.dot}>{'\u2022'}</div>
                      <h4>{i18n('docs_documentation_formats_title')}</h4>
                      <div className={styles.cover}>
                        <Link to={'/docs/formats'}></Link>
                      </div>
                    </li>
                    <li className={styles.nav_active}>
                      <div className={styles.dot}>{'\u2022'}</div>
                      <h4>{i18n('legal_privacy_title')}</h4>
                      <div className={styles.cover}>
                        <Link to={'/docs/privacy-policy'}></Link>
                      </div>
                    </li>
                    <li className={styles.nav_active}>
                      <div className={styles.dot}>{'\u2022'}</div>
                      <h4>{i18n('legal_terms_service_title')}</h4>
                      <div className={styles.cover}>
                        <Link to={'/docs/terms-service'}></Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.content}>
              <Switch>
                <Route exact path={['/docs', '/docs/index', '/docs/main']}>
                  <Intro />
                </Route>
                <Route exact path={'/docs/tasks'}>
                  <Tasks />
                </Route>
                <Route exact path={'/docs/privacy-policy'}>
                  <PrivacyPolicy />
                </Route>
                <Route exact path={'/docs/formats'}>
                  <Formats />
                </Route>
                <Route exact path={'/docs/terms-service'}>
                  <TermsOfService />
                </Route>
                <Route exact path={'/docs/about'}>
                  <About />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withUserProfileSettings(withRouter(Docs));
