import React from 'react';
import styles from './Intro.scss';
import i18n, { URLMarkdown } from 'src/locales';

const Introduction = () => {
  return (
    <div className={styles.docs_intro}>
      <div className={styles.label}>
        <h2>{i18n('docs_introduction_content_label')}</h2>
      </div>
      <div className={styles.icon_one}>
        <div></div>
      </div>
      <div className={styles.message}>
        {URLMarkdown(
          'docs_introduction_content_message',
          { url1: { link: '/docs/about' } },
          '',
          false
        )}
      </div>
      <div className={styles.resources}>
        <h5>
          {URLMarkdown(
            'docs_introduction_questions_or_redirect',
            {
              emph1: {
                link: '#',
                notRedirect: true
              },
              url1: {
                link: 'https://getchamu.com'
              }
            },
            '',
            true
          )}
        </h5>
      </div>
    </div>
  );
};

export default Introduction;
