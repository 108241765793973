import Logger from 'src/lib/Logger';

export function timeout(ms) {
  return new Promise(resolve => {
    const _timeout = setTimeout(
      () => {
        clearTimeout(_timeout);
        return resolve();
      },
      ms ? ms : 50
    );
  });
}

export function getHHMMAMPMFormat(hh, mm) {
  let isPM = false;
  let finalhh = hh;

  if (hh >= 12 && hh <= 24) {
    isPM = true;
  }

  if (isPM) {
    finalhh = hh - 12;
    finalhh = finalhh <= 0 ? 12 : finalhh;

    if (hh === 24) {
      isPM = false;
    }
  }

  if (Number(hh) === 0 || hh === 0) {
    finalhh = 12;
    isPM = false;
  }

  if (`${finalhh}`.length <= 1) {
    finalhh = `0${finalhh}`;
  }

  if (`${mm}`.length <= 1) {
    mm = `0${mm}`;
  }

  return `${finalhh}:${mm} ${isPM ? 'PM' : 'AM'}`;
}

export function daysToSeconds(days) {
  return days * 24 * 60 * 60;
}

export function hrsToSeconds(hrs) {
  return hrs * 60 * 60;
}

export function hrsToMilliseconds(hrs = 0) {
  return hrsToSeconds(hrs) * 1000;
}

export function isYTURL(str = '') {
  // eslint-disable-next-line
  const match =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

  return typeof str === 'string' && str.length > 0 && match.test(str);
}

export function isTwitterStatusURL(str = '') {
  //eslint-disable-next-line
  const match = /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)$/;

  return typeof str === 'string' && str.length && match.test(str);
}

export function getStringSizeKB(str = '') {
  if (typeof str !== 'string') {
    return 0;
  } else {
    return Number((new Blob([`${str}`]).size / 1024).toFixed(2));
  }
}

export function isImageURL(url = '') {
  const match = /\.(jpeg|jpg|gif|png)$/;

  return typeof url === 'string' && url.length > 0 && match.test(url);
}

export function isWebURL(url = '') {
  try {
    if (url && typeof url === 'string' && url.length > 0) {
      const _url = new URL(url);
      if (_url.protocol === 'http://' || _url.protocol === 'https://') {
        return {
          err: false,
          url: encodeURI(url)
        };
      }

      return {
        err: false,
        url
      };
    }
  } catch (err) {
    if (err.stack) {
      Logger.log(err.stack);
    }
  }

  return { err: true, url: '' };
}

export function getSpaceNameInitials(name = '') {
  name = `${name}`.trim();
  const arr = name.split(' ');

  if (arr.length) {
    const firstName = arr[0];
    const lastName = arr[1];

    if (firstName && lastName) {
      return `${firstName.charAt(0)}${
        lastName.charAt(0) ? ` ${lastName.charAt(0)}` : ''
      }`.toUpperCase();
    } else if (firstName) {
      return `${firstName.charAt(0)}${
        firstName.charAt(1) ? ` ${firstName.charAt(1)}` : ''
      }`.toUpperCase();
    }
  }

  return '';
}

export function isValidDescriptionFormat(desc = '') {
  try {
    return Boolean(desc && desc.length && JSON.parse(desc).ops);
  } catch {
    return false;
  }
}
