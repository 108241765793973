class ModalAPI {
  static context = null;
  static closeOnNewMount = false;

  constructor() {
    this.closeOnNewMount = false;
    this.goDeactivate = this.goDeactivate.bind(this);
    this.goActivate = this.goActivate.bind(this);
    this.hideDOM2 = this.hideDOM2.bind(this);
  }

  setContext(ctx) {
    this.context = ctx;
  }

  noEscape() {
    if (this.context) {
      this.context.setState({
        noEscape: true
      });
    }
  }

  hasEscape() {
    if (this.context) {
      this.context.setState({
        noEscape: false
      });
    }
  }

  setDOM(dom = null, active = false) {
    if (this.context && dom) {
      this.context.setState({
        localDOMREF: dom,
        active
      });
    }
  }

  setDOM2(dom = null) {
    if (
      this.context &&
      this.context.state &&
      this.context.state.active &&
      dom
    ) {
      this.context.setState({
        localDOMREF2: dom
      });
    }
  }

  hideDOM2() {
    if (this.context) {
      this.context.setState({
        localDOMREF2: null
      });
    }
  }

  toCloseOnNewMount() {
    return !!this.closeOnNewMount;
  }

  setCloseOnNewMount() {
    this.closeOnNewMount = true;
  }

  goActivate() {
    if (this.context) {
      this.context.setState({
        active: true
      });
    }
  }

  goDeactivate() {
    if (this.context && this.context.state && this.context.state.active) {
      this.context.setState({
        active: false,
        localDOMREF: null,
        localDOMREF2: null
      });
    }
  }

  isActive() {
    return !!(this.context && this.context.state && this.context.state.active);
  }
}

export default new ModalAPI();
