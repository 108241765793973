import Logger from 'src/lib/Logger';
import axios from 'axios';
import k from 'src/constants/k';
import Store from 'src/lib/store';
import i18n from 'src/locales';
import AppAPI from 'src/app-manager/API';
import ProfileAPI from 'src/profile-manager/API';
import { timeout } from 'src/helpers/utils';
import { Subject } from 'rxjs';
import { v4 } from 'uuid';

class UserTasks {
  profile_context = null;
  /**
   * Accumulated tags
   */
  personal_tags = [];
  personal_tags_page = 1;
  personal_tags_total = 0;
  personal_tags_max_page = 0;
  personal_tags_remove_q = [];
  personal_tags_create_q = [];
  resultsFromGettingUserTagsOBS = null;
  resultsFromNewUserTagOBS = null;
  resultsFromRemoveTagOBS = null;
  resultsFromEditTagOBS = null;
  resultsFromNewUserTaskOBS = null;
  resultsFromUpdateUserTaskOBS = null;
  resultsFromDeleteUserTaskOBS = null;
  tasks_page = 1;
  tasks_tags = [];
  tasks_state = k.TASK_STATE_ACTIVE;
  tasks_current_has_next = false;
  tasks_current = [];
  tasks_accumulated = [];
  tasks_nav_changes = true;
  tasks_curr_changes = true;
  tasks_current_active_count = 0;
  tasks_current_created_count = 0;
  tasks_current_inactive_count = 0;
  tasks_total_filtered_fetch = 0; // can be use for fileered tasks, we compute/calc the total
  tasks_saved_users = [];
  tasks_saved_users_pages = [];
  tasks_saved_users_max_page = 1;
  resultsFromGettingSavedUsersOBS = null;
  resultsFromGettingTasksOBS = null;
  gettingUserTasksOBS = null;
  resultsFromGettingSearch = null;
  gettingUserSearchOBS = null;
  onSearchUIFlagOBS = null;
  tasks_search_ui = false;
  tasks_search_results = [];
  tasks_search_results_page = 1;
  tasks_search_key_words = '';
  onUserLogoutSubscriber = null;
  resultsFromCommentsListOBS = null;

  VARS = {
    firstTags: false,
    completeTags: false,
    gettingTags: false,
    gettingTasks: true,
    removingTag: '',
    gettingTasksLatestKey: '',
    creatingTask: false,
    gettingSearchResults: false,
    searchUI: false
  };

  constructor() {
    this.resultsFromGettingUserTagsOBS = new Subject();
    this.resultsFromNewUserTagOBS = new Subject();
    this.resultsFromUpdateUserTaskOBS = new Subject();
    this.resultsFromDeleteUserTaskOBS = new Subject();
    this.resultsFromRemoveTagOBS = new Subject();
    this.resultsFromEditTagOBS = new Subject();
    this.resultsFromGettingTasksOBS = new Subject();
    this.gettingUserTasksOBS = new Subject();
    this.resultsFromNewUserTaskOBS = new Subject();
    this.resultsFromGettingSearch = new Subject();
    this.gettingUserSearchOBS = new Subject();
    this.onSearchUIFlagOBS = new Subject();
    this.resultsFromCommentsListOBS = new Subject();
    this.resultsFromGettingSavedUsersOBS = new Subject();
    this.hideSearchUI = this.hideSearchUI.bind(this);
    this.goSearchUI = this.goSearchUI.bind(this);
  }

  start() {
    this.onUserLogoutSubscriber = ProfileAPI.onLogout(this.onLogout.bind(this));
  }

  release() {
    this.resultsFromGettingUserTagsOBS.unsubscribe();
    this.resultsFromNewUserTagOBS.unsubscribe();
    this.resultsFromUpdateUserTaskOBS.unsubscribe();
    this.resultsFromDeleteUserTaskOBS.unsubscribe();
    this.resultsFromRemoveTagOBS.unsubscribe();
    this.resultsFromEditTagOBS.unsubscribe();
    this.resultsFromGettingTasksOBS.unsubscribe();
    this.gettingUserTasksOBS.unsubscribe();
    this.resultsFromNewUserTaskOBS.unsubscribe();
    this.resultsFromGettingSearch.unsubscribe();
    this.gettingUserSearchOBS.unsubscribe();
    this.onSearchUIFlagOBS.unsubscribe();
    this.resultsFromCommentsListOBS.unsubscribe();
    this.resultsFromGettingSavedUsersOBS.unsubscribe();
  }

  onLogout() {
    Logger.log(`Clearing USER_TASKS vars`);
    this.resetVARS();
  }

  resetVARS() {
    this.VARS = {
      firstTags: false,
      completeTags: false,
      gettingTags: false,
      removingTag: '',
      gettingTasks: true,
      gettingTasksLatestKey: '',
      creatingTask: false,
      gettingSearchResults: false,
      searchUI: false
    };

    this.personal_tags = [];
    this.personal_tags_page = 1;
    this.personal_tags_total = 0;
    this.personal_tags_max_page = 0;

    this.tasks_page = 1;
    this.tasks_tags = [];
    this.tasks_state = k.TASK_STATE_ACTIVE;
    this.tasks_current_has_next = false;
    this.tasks_current = [];
    this.tasks_accumulated = [];
    this.tasks_nav_changes = true;
    this.tasks_curr_changes = true;
    this.tasks_current_active_count = 0;
    this.tasks_current_created_count = 0;
    this.tasks_current_inactive_count = 0;
    this.tasks_total_filtered_fetch = 0;
    this.tasks_search_results = [];
    this.tasks_search_results_page = 1;
    this.tasks_search_key_words = '';

    this.tasks_saved_users = [];
    this.tasks_saved_users_pages = [];
    this.tasks_saved_users_max_page = 1;
  }

  getActiveTasksCount() {
    return this.tasks_current_active_count;
  }

  getInactiveTasksCount() {
    return this.tasks_current_inactive_count;
  }

  getCreatedTasksCount() {
    return this.tasks_current_created_count;
  }

  setContext(profile_context) {
    this.profile_context = profile_context;
  }

  onPersonalTagsResults(fn) {
    if (this.resultsFromGettingUserTagsOBS) {
      return this.resultsFromGettingUserTagsOBS.subscribe({ next: fn });
    } else return null;
  }

  onCreateTagResults(fn) {
    if (this.resultsFromNewUserTagOBS) {
      return this.resultsFromNewUserTagOBS.subscribe({ next: fn });
    } else return null;
  }

  onRemoveTagResults(fn) {
    if (this.resultsFromRemoveTagOBS) {
      return this.resultsFromRemoveTagOBS.subscribe({ next: fn });
    } else return null;
  }

  onEditTagResults(fn) {
    if (this.resultsFromEditTagOBS) {
      return this.resultsFromEditTagOBS.subscribe({ next: fn });
    } else return null;
  }

  onGettingTasksList(fn) {
    if (this.gettingUserTasksOBS) {
      return this.gettingUserTasksOBS.subscribe({ next: fn });
    } else return null;
  }

  onSearchQryResults(fn) {
    if (this.resultsFromGettingSearch) {
      return this.resultsFromGettingSearch.subscribe({ next: fn });
    } else return null;
  }

  onSearchQry(fn) {
    if (this.gettingUserSearchOBS) {
      return this.gettingUserSearchOBS.subscribe({ next: fn });
    } else return null;
  }

  tagNameToID(name) {
    return name.split(' ').join('_');
  }

  tagIDToName(id = '') {
    return id.split('_').join(' ');
  }

  tagExists(tag, type = 'id') {
    for (let i = 0; i < this.personal_tags.length; i++) {
      const t = this.personal_tags[i];

      if (t[type] && t[type] === tag) {
        return Number(i);
      }
    }

    return -1;
  }

  async userTagsGET() {
    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;

        if (!isLoggedIn) {
          return;
        } else {
          this.VARS.gettingTags = true;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'GET',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          url: `/v1/user/tasks/personal-tags?auth_token_id=${id}&lb=${lb}&n=${n}&page=${this.personal_tags_page}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res.status === 200 && res.data && res.data.success) {
          if (this.personal_tags_page === 1) {
            this.VARS.firstTags = true;
            this.VARS.completeTags = false;
            this.personal_tags = [];
          }

          this.personal_tags_total = res.data.total_count;
          this.personal_tags = [...this.personal_tags, ...res.data.value];
          this.personal_tags_max_page = Math.ceil(
            this.personal_tags_total / k.USER_TAG_MAX_BATCH
          );

          if (this.personal_tags_page < this.personal_tags_max_page) {
            Logger.log(
              `Fetching additional created tags from user, since > max count/page: ${this.personal_tags_total}`
            );

            this.resultsFromGettingUserTagsOBS.next({
              personal_tags: this.personal_tags
            });
            this.personal_tags_page += 1;
            await timeout(200); // delay/gap
            return this.userTagsGET();
          } else {
            this.VARS.gettingTags = false;
            this.VARS.completeTags = true;
            this.resultsFromGettingUserTagsOBS.next({
              personal_tags: this.personal_tags
            });

            Logger.log(`Tags total pages: ${this.personal_tags_max_page}`);
          }
        } else if (res.data && res.data.auth_expired) {
          this.VARS.gettingTags = false;
          return await ProfileAPI.sendLogout();
        } else if (
          res.status === 401 ||
          res.status === 404 ||
          (res.data && res.data.removed)
        ) {
          return await ProfileAPI.sendLogout();
        } else {
          throw new Error(
            `userTagsGET unhandled if-else block result: ${JSON.stringify(
              res.data
            )}`
          );
        }
      } catch (err) {
        /**
         * Longer spinner shall indicate user to refresh browser
         */

        if (err.stack) {
          Logger.log(err.stack);
        }

        Logger.log(`Failed to fetch user personal tags: ${err.message}`);
        this.VARS.gettingTags = false;
      }
    }
  }

  async createTag(tag = '') {
    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;
        if (!isLoggedIn) return;
        else if (this.personal_tags_total >= k.USER_TAG_MAX_CREATE) {
          return this.resultsFromNewUserTagOBS.next({
            max_reached: true,
            success: false
          });
        }

        if (this.tagExists(tag, 'name') >= 0) {
          this.resultsFromNewUserTagOBS.next({
            already_taken: true,
            success: false
          });
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: { tag },
          url: `/v1/user/tasks/create-tag?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 201 && res.data && res.data.success) {
          this.personal_tags = [
            {
              name: tag,
              id: this.tagNameToID(tag),
              tasks_under_count: 0
            },
            ...this.personal_tags
          ];
          this.personal_tags_total += 1;
          this.resultsFromNewUserTagOBS.next({
            success: true,
            respond: res.data
          });
          this.resultsFromGettingUserTagsOBS.next({
            personal_tags: this.personal_tags
          });
        } else if (res.data && res.data.already_taken) {
          this.resultsFromNewUserTagOBS.next({
            success: false,
            already_taken: true
          });
        } else if (res.data && res.data.max_reached) {
          this.resultsFromNewUserTagOBS.next({
            success: false,
            max_reached: true
          });
        } else {
          this.resultsFromNewUserTagOBS.next({
            success: false
          });
        }
      } catch (err) {
        if (err.stack) {
          Logger.log(err.stack);
        }

        Logger.log(`Failed to submit new tag: ${err.message}`);

        this.resultsFromNewUserTagOBS.next({
          success: false,
          already_taken: false,
          max_reached: false
        });
      }
    }
  }

  async editTag(old_tag = '', new_tag = '') {
    if (this.profile_context) {
      const { isLoggedIn, user } = this.profile_context.state;
      const idx = this.tagExists(old_tag, 'name');
      const idxNew = this.tagExists(new_tag, 'name');
      const old_tag_id = this.tagNameToID(old_tag);

      if (!isLoggedIn || idx < 0 || this.VARS.removingTag.length > 0) {
        this.resultsFromEditTagOBS.next({ success: false });
        return;
      } else if (idxNew >= 0) {
        this.resultsFromEditTagOBS.next({
          already_taken: true,
          success: false
        });
      }

      try {
        const new_tag_id = this.tagNameToID(new_tag);
        this.personal_tags[idx].id = new_tag_id;
        this.personal_tags[idx].name = new_tag;
        this.resultsFromGettingUserTagsOBS.next({
          personal_tags: this.personal_tags
        });

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'PUT',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: { old_tag, new_tag },
          url: `/v1/user/tasks/edit-tag?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data && res.data.success) {
          this.resultsFromEditTagOBS.next({ success: true });
          let hasChange = false;

          if (!this.VARS.gettingTasks) {
            for (const task of this.tasks_current) {
              if (task && Array.isArray(task.personal_tags)) {
                const tagPosIdx = task.personal_tags.indexOf(old_tag_id);

                if (tagPosIdx > -1) {
                  task.personal_tags[tagPosIdx] = `${new_tag_id}`;
                  hasChange = true;
                }
              }
            }

            if (hasChange) {
              this.gettingUserTasksOBS.next({
                fetching: false,
                success: true,
                unique_key: this.VARS.gettingTasksLatestKey
              });
            }
          }
        } else {
          throw new Error(
            `Failed to edit tag: ${old_tag} with: ${new_tag}, status code: ${res.status}`
          );
        }
      } catch (err) {
        if (err && err.stack) {
          Logger.log(err.stack);
        }

        Logger.log(`Failed to edit tag: ${old_tag}, error: ${err.message}`);
        this.personal_tags[idx].id = old_tag_id;
        this.personal_tags[idx].name = old_tag;
        this.resultsFromGettingUserTagsOBS.next({
          personal_tags: this.personal_tags
        });
        this.resultsFromEditTagOBS.next({ success: false });
      }
    }
  }

  async removeTag(tag = '') {
    if (this.profile_context) {
      const { isLoggedIn, user } = this.profile_context.state;
      const idx = this.tagExists(tag, 'name');
      if (!isLoggedIn || idx < 0) return;
      else if (this.VARS.removingTag.length > 0) {
        this.personal_tags_remove_q.push(tag);
      } else this.VARS.removingTag = tag;

      const { token, id, lb, n, username_ref } = user.auth;
      const { tasks_state, tasks_page } = this;
      const t = this.personal_tags[idx];
      const tag_id = `${t.id}`;

      try {
        const res = await axios({
          method: 'DELETE',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: { tag },
          url: `/v1/user/tasks/remove-tag?auth_token_id=${id}&lb=${lb}&n=${n}&kind=${tasks_state}&p=${tasks_page}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });
        const tasks = AppAPI.getLocalConfig('tasks');

        if (res && res.status === 200 && res.data.success) {
          Logger.log(`Successfully removed tag: ${tag}`);
          this.personal_tags.splice(idx, 1);
          this.VARS.removingTag = '';
          this.resultsFromRemoveTagOBS.next({
            success: true,
            tag_id,
            tag_name: tag
          });
          this.resultsFromGettingUserTagsOBS.next({
            personal_tags: this.personal_tags
          });
          this.personal_tags_total -= 1;
          let hasChange = false;

          if (!this.VARS.gettingTasks) {
            for (let i = 0; i < this.tasks_current.length; i++) {
              const task = this.tasks_current[i];

              if (task && Array.isArray(task.personal_tags)) {
                const tagPosIdx = task.personal_tags.indexOf(tag_id);

                if (tagPosIdx > -1) {
                  task.personal_tags.splice(tagPosIdx, 1);
                  hasChange = true;
                }
              }
            }

            if (hasChange) {
              this.gettingUserTasksOBS.next({
                fetching: false,
                success: true,
                unique_key: this.VARS.gettingTasksLatestKey
              });
            }
          }

          if (
            t &&
            (!tasks || (tasks.selectedTags && tasks.selectedTags[0] === tag_id))
          ) {
            this.clearTag();
            await AppAPI.setLocalConfigs('tasks', {
              ...(tasks || {}),
              selectedTags: []
            });
            await this.get();
            hasChange = false;

            for (let i = 0; i < this.tasks_current.length; i++) {
              const task = this.tasks_current[i];

              if (task && Array.isArray(task.personal_tags)) {
                const tagPosIdx = task.personal_tags.indexOf(tag_id);

                if (tagPosIdx > -1) {
                  task.personal_tags.splice(tagPosIdx, 1);
                  hasChange = true;
                }
              }
            }

            if (hasChange) {
              this.gettingUserTasksOBS.next({
                fetching: false,
                success: true,
                unique_key: this.VARS.gettingTasksLatestKey
              });
            }
          }

          if (this.personal_tags_remove_q.length > 0) {
            return this.removeTag(this.personal_tags_remove_q.shift());
          }
        } else {
          throw new Error(`Failed in removing tag: ${tag}, unexpected code`);
        }
      } catch (err) {
        if (err.stack) {
          Logger.log(err.stack);
        }

        Logger.log(`Failed to remove tag: ${tag}`);
        this.VARS.removingTag = '';
        this.resultsFromRemoveTagOBS.next({ success: false, tag_id });
      }
    }
  }

  onCreateResults(fn) {
    if (this.resultsFromNewUserTaskOBS) {
      return this.resultsFromNewUserTaskOBS.subscribe({ next: fn });
    }

    return null;
  }

  onEditResults(fn) {
    if (this.resultsFromUpdateUserTaskOBS) {
      return this.resultsFromUpdateUserTaskOBS.subscribe({ next: fn });
    }

    return null;
  }

  onDeleteResults(fn) {
    if (this.resultsFromDeleteUserTaskOBS) {
      return this.resultsFromDeleteUserTaskOBS.subscribe({ next: fn });
    }

    return null;
  }

  async edit(
    task_id = '',
    universal_id = '',
    title = '',
    description = '',
    description_delta = '',
    tags = [],
    priority = 2,
    exposure = 1,
    exposure_pw = '',
    task_state,
    subscribers = [],
    subscribers_remove = []
  ) {
    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;
        const { token, id, lb, n, username_ref } = user.auth;

        if (!isLoggedIn) {
          return;
        } else
          Logger.log(`Sending update for task: ${task_id}, ${universal_id}`);
        const taskIdLowerCaps = task_id.toLowerCase();
        const res = await axios({
          method: 'PUT',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            title,
            description,
            description_delta,
            priority,
            tags,
            exposure,
            exposure_pw,
            task_state,
            subscribers,
            subscribers_remove
          },
          url: `/v1/user/tasks/update?auth_token_id=${id}&lb=${lb}&n=${n}&task_id=${taskIdLowerCaps}&u_ref=${universal_id}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data && res.data.success) {
          ProfileAPI.TASKS.cache(taskIdLowerCaps, universal_id, {
            title,
            description_delta,
            priority,
            exposure,
            exposure_pw,
            task_state,
            personal_tags: tags
          });

          this.resultsFromUpdateUserTaskOBS.next({
            success: true,
            taskID: task_id,
            universalID: universal_id
          });
        } else {
          this.resultsFromUpdateUserTaskOBS.next({
            success: false,
            taskID: task_id,
            universalID: universal_id,
            editingBlocked: Boolean(
              res && res.data && res.data.not_allowed_editor
            )
          });
        }
      } catch (err) {
        Logger.log(`Failed to edit user task: ${err.message}`);

        this.resultsFromUpdateUserTaskOBS.next({ success: false });
      }
    }
  }

  async delete(task_id = '', u_ref = '', updateList = false, clearTag = true) {
    const result = {
      success: false
    };

    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;
        const { token, id, lb, n, username_ref } = user.auth;

        if (!isLoggedIn) {
          return;
        }
        const taskIdLowerCaps = task_id.toLowerCase();
        const res = await axios({
          method: 'PUT',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            task_id: taskIdLowerCaps,
            u_ref
          },
          url: `/v1/user/tasks/delete?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data && res.data.success) {
          if (updateList) {
            // update current page
            await this.get('', false);
          } else {
            if (clearTag) {
              this.clearTag();
            }
          }

          result.success = true;
          ProfileAPI.TASKS.cache(taskIdLowerCaps, u_ref, {
            deleted: true
          });
          this.resultsFromDeleteUserTaskOBS.next({ success: true });
        } else this.resultsFromDeleteUserTaskOBS.next({ success: false });
      } catch (err) {
        Logger.log(
          `Failed to remove task: ${task_id} ${u_ref}: ${err.message}`
        );

        this.resultsFromDeleteUserTaskOBS.next({ success: false });
      }
    }

    return result;
  }

  async create(
    title = '',
    description = '',
    description_delta = '',
    tags = [],
    priority = 2,
    exposure = 1,
    exposure_pw = '',
    subscribers = [],
    u_ref = ''
  ) {
    if (this.profile_context) {
      try {
        if (this.VARS.creatingTask) {
          return Logger.log(
            `User tried creating new task but wasn't done with previous create`
          );
        }

        const { isLoggedIn, user } = this.profile_context.state;
        const { token, id, lb, n, username_ref } = user.auth;

        if (!isLoggedIn) {
          return;
        } else {
          this.VARS.creatingTask = true;
        }

        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            title,
            description,
            description_delta,
            priority,
            tags,
            exposure,
            exposure_pw,
            subscribers,
            u_ref
          },
          url: `/v1/user/tasks/new?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        this.VARS.creatingTask = false;
        if (res && res.status === 201 && res.data && res.data.success) {
          this.resultsFromNewUserTaskOBS.next({
            success: true,
            taskID: res.data.task_id,
            universalID: res.data.universal_id
          });

          if (ProfileAPI && ProfileAPI.USER_DATA) {
            await ProfileAPI.USER_DATA.increaseCreatedTaskInMonthUI();
          }

          await this.get('', false);
        } else if (res && res.data && res.data.not_allowed) {
          this.resultsFromNewUserTaskOBS.next({ not_allowed: true });
        } else if (res && res.data && res.data.not_allowed_plan) {
          /**
           * @todo log on cloud that user try to create task w/ unfit plan
           */
          Logger.log(`Not allowed w/ user plan`);
          this.resultsFromNewUserTaskOBS.next({ not_allowed_plan: true });
        } else if (res && res.data && res.data.try_again) {
          this.resultsFromNewUserTaskOBS.next({ try_again: true });
        } else if (res && res.data && res.data.invalid) {
          this.resultsFromNewUserTaskOBS.next({ try_again: true });
        } else {
          /**
           * Failed
           */
          this.resultsFromNewUserTaskOBS.next({ success: false });
        }
      } catch (err) {
        Logger.log(`Failed to create user task: ${err.message}`);
        this.VARS.creatingTask = false;
        this.resultsFromNewUserTaskOBS.next({ success: false });
      }
    }
  }

  getTaskState() {
    return this.tasks_state;
  }

  setTaskState(tasks_state) {
    /**
     * Setting task state changes page navigation
     */
    this.tasks_nav_changes = true;
    this.tasks_curr_changes = true;
    this.tasks_state = tasks_state;
    this.tasks_page = 1;
    this.tasks_accumulated.length = 0;
    this.tasks_total_filtered_fetch = 0;
  }

  setTag(tag = '') {
    for (let i = 0; i < this.personal_tags.length; i++) {
      if (this.personal_tags[i].id === tag) {
        Logger.log(`Applying tag: ${tag}`);
        this.tasks_nav_changes = true;
        this.tasks_curr_changes = true;
        this.tasks_tags = [`${tag}`];
        this.tasks_page = 1;
        this.tasks_accumulated.length = 0;
        this.tasks_total_filtered_fetch = 0;
        break;
      }
    }
  }

  clearTag() {
    this.tasks_nav_changes = true;
    this.tasks_curr_changes = true;
    this.tasks_tags = [];
    this.tasks_page = 1;
    this.tasks_accumulated.length = 0;
    this.tasks_total_filtered_fetch = 0;
    this.tasks_current_has_next = false;
    this.tasks_current = [];
  }

  removeFilterTag(tag = '') {
    for (let i = 0; i < this.tasks_tags.length; i++) {
      const tag_id = this.tasks_tags[i];

      if (tag === tag_id) {
        this.tasks_nav_changes = true;
        this.tasks_curr_changes = true;
        this.tasks_page = 1;
        this.tasks_tags.splice(i, 1);
        this.tasks_accumulated.length = 0;
        this.tasks_total_filtered_fetch = 0;
        break;
      }
    }
  }

  setClearCurrNav() {
    this.tasks_nav_changes = true;
    this.tasks_curr_changes = true;
    this.tasks_tags = [];
    this.tasks_page = 1;
    this.tasks_state = 'active';
    this.tasks_accumulated.length = 0;
    this.tasks_total_filtered_fetch = 0;
  }

  setPage(tasks_page) {
    this.tasks_page = tasks_page;
    this.tasks_nav_changes = true;
  }

  getPage() {
    return this.tasks_page;
  }

  hasFilteredTags() {
    return this.tasks_tags.length > 0;
  }

  getNavRange() {
    const hasTags = this.hasFilteredTags();
    const res = {
      start: '',
      total: '',
      end: '',
      page: this.tasks_page
    };

    if (hasTags) {
      if (
        this.tasks_current.length >= k.USER_TASKS_MAX_BATCH &&
        this.tasks_current_has_next
      ) {
        res.total = i18n('user_task_view_many_title');
      } else
        res.total = `${this.tasks_accumulated.reduce((acc, curr) => {
          if (Array.isArray(curr)) {
            acc += curr.length;
          }

          return acc;
        }, 0)}`;
    } else {
      if (this.tasks_state === 'active') {
        res.total = `${this.tasks_current_active_count}`;
      } else if (this.tasks_state === 'inactive') {
        res.total = `${this.tasks_current_inactive_count}`;
      } else if (this.tasks_state === 'created') {
        res.total = `${this.tasks_current_created_count}`;
      } else {
        res.total = `n/a`;
      }
    }

    res.start = (this.tasks_page - 1) * k.USER_TASKS_MAX_BATCH + 1;
    res.end =
      (this.tasks_page - 1) * k.USER_TASKS_MAX_BATCH +
      this.tasks_current.length;

    if (res.end <= 0) {
      res.start = 0;
    }

    return res;
  }

  async check() {
    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;
        const { tasks_state, tasks_page, tasks_tags } = this;

        if (!isLoggedIn) return;
        else {
          const final_tags = tasks_tags.reduce((acc, curr) => {
            if (`${acc}`.length > 0) {
              acc += `,${curr}`;
            } else {
              acc += curr;
            }

            return acc;
          }, '');

          Logger.log(
            `Checking task list ${
              tasks_tags.length > 0 ? `with tags: ${final_tags}, ` : 'no tags, '
            }on page: ${tasks_page}, on state: ${tasks_state} `
          );

          const { token, id, lb, n, username_ref } = user.auth;
          const res = await axios({
            method: 'GET',
            baseURL:
              k.API_DASHBOARD_URLS[
                Store.CONFIGS.IS_DEV ? 'development' : 'production'
              ],
            headers: {
              authorization: `BEARER ${token}`,
              'x-custom-user-session': `${username_ref}`
            },
            url: `/v1/user/tasks/list?auth_token_id=${id}&lb=${lb}&n=${n}&kind=${tasks_state}&check=1&p=${tasks_page}${
              tasks_tags.length > 0 ? `&tags=${final_tags}` : ''
            }`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          });

          if (!this.profile_context.state.isLoggedIn) {
            return;
          } else if (res && res.status === 200 && res.data.success) {
            Logger.log(
              'Successfully performed check against current task list'
            );
          } else {
            Logger.log(
              `Failed to check current task list: ${`status - ${res.status} ${res.statusText}`}`
            );
          }

          if (
            res.status === 401 ||
            res.status === 404 ||
            (res.data && res.data.removed)
          ) {
            return await ProfileAPI.sendLogout();
          }
        }
      } catch (err) {
        Logger.log(`Failed to check current task list: ${err.message}`);
      }
    }
  }

  getTasksCurrentCount() {
    return {
      tasks_current_active_count: this.tasks_current_active_count,
      tasks_current_inactive_count: this.tasks_current_inactive_count,
      tasks_current_created_count: this.tasks_current_created_count
    };
  }

  async get(unique_key = '', check = true) {
    if (this.profile_context) {
      try {
        /**
         * Task fetch on:
         * 1. New tag(s) selected
         * 2. New status type (active/created/inactive)
         * 3. Next/prev page
         */
        const { isLoggedIn, user } = this.profile_context.state;
        const { tasks_state, tasks_page, tasks_tags } = this;

        if (!isLoggedIn) return;
        else {
          if (unique_key.length < 1) {
            unique_key = v4();
            this.VARS.gettingTasksLatestKey = unique_key;
          } else this.VARS.gettingTasksLatestKey = unique_key;

          this.VARS.gettingTasks = true;
          this.gettingUserTasksOBS.next({
            fetching: true
          });

          if (check) {
            this.check();
          }
        }

        const final_tags = tasks_tags.reduce((acc, curr) => {
          if (`${acc}`.length > 0) {
            acc += `,${curr}`;
          } else {
            acc += curr;
          }

          return acc;
        }, '');

        Logger.log(
          `Fetching task list ${
            tasks_tags.length > 0 ? `with tags: ${final_tags}, ` : 'no tags, '
          }on page: ${tasks_page}, on state: ${tasks_state} `
        );

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'GET',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],

          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          url: `/v1/user/tasks/list?auth_token_id=${id}&lb=${lb}&n=${n}&kind=${tasks_state}&check=0&p=${tasks_page}${
            tasks_tags.length > 0 ? `&tags=${final_tags}` : ''
          }`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (
          !this.profile_context.state.isLoggedIn ||
          this.VARS.gettingTasksLatestKey !== unique_key
        ) {
          return;
        } else if (res && res.status === 200 && res.data.success) {
          const {
            has_next = false,
            value = [],
            current_count_active,
            current_count_created,
            current_count_inactive
          } = res.data;

          if (value && Array.isArray(value)) {
            for (const task of value) {
              if (task && task.task_id && task.universal_ref_map) {
                const { universal_ref_map = '', task_id = '' } = task;

                ProfileAPI.TASKS.cache(task_id, universal_ref_map, task);
              }
            }
          }

          this.tasks_current_active_count = current_count_active;
          this.tasks_current_created_count = current_count_created;
          this.tasks_current_inactive_count = current_count_inactive;
          this.tasks_curr_changes = false;
          this.tasks_current_has_next = has_next;
          this.tasks_current = value;
          this.tasks_accumulated[tasks_page] = value;
          this.VARS.gettingTasks = false;
          this.gettingUserTasksOBS.next({
            fetching: false,
            success: true,
            unique_key
          });
        } else if (
          res.status === 401 ||
          res.status === 404 ||
          (res.data && res.data.removed)
        ) {
          return await ProfileAPI.sendLogout();
        }
      } catch (err) {
        this.VARS.gettingTasks = false;

        Logger.log(`Failed to fetch task list: ${err.message}`);
      }
    }
  }

  async getSubscribers(taskID = '', u_ref = '') {
    try {
      if (this.profile_context) {
        const { user = {}, isLoggedIn } = this.profile_context.state;

        if (!isLoggedIn) {
          return [];
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'PUT',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            u_ref,
            task_id: taskID.toLowerCase()
          },
          url: `/v1/task/subscribers?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.status === 200 && res.data && res.data.valid) {
          return res.data.subscribers;
        }
      }

      return [];
    } catch (err) {
      if (err && err.stack) {
        Logger.log(err.stack);
      }

      Logger.log(`Failed to fetch task subscribers: ${err.message}`);

      return [];
    }
  }

  setSearchKeyWords(kw = '') {
    if (kw) {
      this.tasks_search_key_words = kw;

      if (this.tasks_search_key_words.length < 1) {
        this.tasks_search_results = '';
        this.tasks_search_results_page = 1;
      }
    }
  }

  getSearchKeyWords() {
    return this.tasks_search_key_words;
  }

  onSearchUIChanges(fn) {
    if (this.onSearchUIFlagOBS) {
      return this.onSearchUIFlagOBS.subscribe({ next: fn });
    }

    return null;
  }

  goSearchUI() {
    this.VARS.searchUI = true;
    this.onSearchUIFlagOBS.next({ show: true });
  }

  hideSearchUI() {
    this.VARS.searchUI = false;
    this.tasks_search_key_words = '';
    this.onSearchUIFlagOBS.next({ show: false });
  }

  resetSearch() {
    this.tasks_search_results.length = 0;
    this.tasks_search_results_page = 1;
  }

  async search(search = '') {
    if (this.profile_context) {
      if (search.length < 1) {
        this.tasks_search_results = [];
        this.resultsFromGettingSearch.next({ value: [] });
        return;
      }

      try {
        /**
         * Task fetch on:
         * 1. New tag(s) selected
         * 2. New status type (active/created/inactive)
         * 3. Next/prev page
         */
        const { isLoggedIn, user } = this.profile_context.state;

        if (!isLoggedIn) return;
        else {
          this.VARS.gettingSearchResults = true;
          this.gettingUserSearchOBS.next({ fetching: true });
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_OPEN_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            search
          },
          url: `/v1/task/search?auth_token_id=${id}&lb=${lb}&n=${n}&p=1`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.success) {
          this.tasks_search_results = res.data.value;
          this.resultsFromGettingSearch.next({
            value: this.tasks_search_results
          });
        } else this.resultsFromGettingSearch.next({ value: [] });
      } catch (err) {
        Logger.log(`Failed to get results in search: ${err.message}`);
      }

      this.VARS.gettingSearchResults = false;
      this.gettingUserSearchOBS.next({ fetching: false });
    }
  }

  onCommentsList(fn) {
    if (this.resultsFromCommentsListOBS) {
      return this.resultsFromCommentsListOBS.subscribe({ next: fn });
    }

    return null;
  }

  async comments(task_id = '', u_ref = '', page = 1, key = '') {
    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;
        const result = {
          valid: false,
          value: [],
          has_next: false,
          has_prev: false,
          not_allowed: false,
          block_write: false,
          key: key ? key : v4(),
          limit_reached: false,
          page
        };

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_OPEN_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            ...(isLoggedIn && {
              authorization: `BEARER ${token}`,
              'x-custom-user-session': `${username_ref}`
            })
          },
          data: { p: page, task_id, u_ref },
          url: `/v1/task/list-comment${
            isLoggedIn ? `?auth_token_id=${id}&lb=${lb}&n=${n}` : ''
          }`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          result.valid = !!res.data.valid;
          result.value = res.data.value;
          result.not_allowed = !!res.data.not_allowed;
          result.block_write = !!res.data.block_write;
          result.has_next = !!res.data.has_prev;
          result.has_next = !!res.data.has_next;
          result.limit_reached = !!res.data.limit_reached;
        }

        this.resultsFromCommentsListOBS.next(result);
      } catch (err) {
        Logger.log(
          `Failed to get comments on page: ${page} for task ${task_id}/${u_ref}, error: ${err.message}`
        );
      }
    }
  }

  async deleteComment(ref_id = '', task_id = '', u_ref = '') {
    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;
        const result = {
          success: false,
          try_again: false,
          block_write: false
        };

        if (!isLoggedIn) {
          return;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_OPEN_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: { ref_id, u_ref, task_id: task_id.toLowerCase() },
          url: `/v1/task/delete-comment?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          result.success = !!res.data.valid;
          result.try_again = !!res.data.try_again;
          result.block_write = !!res.data.block_write;
        }

        return result;
      } catch (err) {
        Logger.log(
          `Failed to delete comment: ${ref_id} for task ${task_id}, ${u_ref}, error: ${err.message}`
        );
      }
    }
  }

  async writeComment(comment = '', task_id = '', u_ref = '') {
    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;
        const result = {
          success: false,
          try_again: false,
          limit_reached: false,
          block_write: false
        };

        if (!isLoggedIn) {
          return;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_OPEN_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: { text: comment, task_id: task_id.toLowerCase(), u_ref },
          url: `/v1/task/comment?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          result.success = !!res.data.valid;
          result.try_again = !!res.data.try_again;
          result.limit_reached = !!res.data.limit_reached;
          result.block_write = !!res.data.block_write;
        }

        return result;
      } catch (err) {
        Logger.log(
          `Failed to write comment for task ${task_id}, ${u_ref}, error: ${err.message}`
        );
      }
    }
  }

  async updateComment(new_comment = '', ref_id = '', task_id = '', u_ref = '') {
    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;
        const result = {
          success: false,
          try_again: false,
          limit_reached: false,
          block_write: false
        };

        if (!isLoggedIn) {
          return;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_OPEN_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            new_text: new_comment,
            task_id: task_id.toLowerCase(),
            u_ref,
            ref_id
          },
          url: `/v1/task/update-comment?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          result.success = !!res.data.valid;
          result.try_again = !!res.data.try_again;
        }

        return result;
      } catch (err) {
        Logger.log(
          `Failed to write comment for task ${task_id}, ${u_ref}, error: ${err.message}`
        );
      }
    }
  }

  async advancedOptions(task_id = '', u_ref = '') {
    if (this.profile_context) {
      const { isLoggedIn, user } = this.profile_context.state;
      const result = {
        success: false,
        allowComments: true,
        allowSubsEdit: false
      };

      try {
        if (!isLoggedIn) {
          return;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_OPEN_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            task_id: task_id.toLowerCase(),
            u_ref
          },
          url: `/v1/task/advanced-options?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          result.success = !!res.data.valid;
          result.allowComments = !!res.data.allow_comments;
          result.allowSubsEdit = !!res.data.allow_subs_edit;
        }

        return result;
      } catch (err) {
        Logger.log(
          `Failed to fetch advanced options for task ${task_id}, ${u_ref}, error: ${err.message}`
        );

        return result;
      }
    }
  }

  async applyAdvancedOptions(
    task_id = '',
    u_ref = '',
    allowComments,
    allowSubsEdit
  ) {
    if (this.profile_context) {
      const { isLoggedIn, user } = this.profile_context.state;
      const result = {
        success: false
      };

      try {
        if (!isLoggedIn) {
          return;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_OPEN_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            task_id: task_id.toLowerCase(),
            u_ref,
            opts: {
              ...(typeof allowComments === 'boolean' && {
                allow_comments: allowComments
              }),
              ...(typeof allowSubsEdit === 'boolean' && {
                allow_subs_edit: allowSubsEdit
              })
            }
          },
          url: `/v1/task/update-advanced-options?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          result.success = !!res.data.valid;
        }

        return result;
      } catch (err) {
        Logger.log(
          `Failed to modify advanced options for task ${task_id}, ${u_ref}, error: ${err.message}`
        );

        return result;
      }
    }
  }

  async remove(task_id = '', u_ref = '') {
    const result = {
      success: false,
      task_id: task_id,
      u_ref: u_ref
    };

    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;

        if (!isLoggedIn) {
          return;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            task_id: task_id.toLowerCase(),
            u_ref
          },
          url: `/v1/task/remove?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          result.success = res.data.success;

          if (result.success) {
            for (let i = 0; i < this.tasks_current.length; i++) {
              const t = this.tasks_current[i];

              if (t && t.task_id === task_id && t.universal_ref_map === u_ref) {
                await this.get();
                break;
              }
            }
          }
        }

        return result;
      } catch (err) {
        Logger.log(
          `Failed to remove task id: ${task_id}, u_ref: ${u_ref} error: ${err.message}`
        );

        return result;
      }
    }
  }

  async updateState(new_state = '', task_id = '', u_ref = '') {
    const result = {
      success: false,
      task_id: task_id,
      u_ref: u_ref
    };

    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;

        if (!isLoggedIn) {
          return;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: {
            task_id: task_id.toLowerCase(),
            task_state: new_state,
            u_ref
          },
          url: `/v1/task/set-state?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          result.success = res.data.success;

          if (result.success) {
            for (let i = 0; i < this.tasks_current.length; i++) {
              const t = this.tasks_current[i];

              if (
                this.tasks_state !== k.TASK_STATE_CREATED &&
                t &&
                t.task_id === task_id &&
                t.universal_ref_map === u_ref
              ) {
                await this.get('', false);
                // @todo do not show loading spinner
                break;
              } else if (this.tasks_state === k.TASK_STATE_CREATED) {
                if (this.tasks_current[i]) {
                  this.tasks_current[i]['task_state'] = new_state;
                  this.tasks_current[i]['task_states'] = [`${new_state}`];
                }

                this.gettingUserTasksOBS.next({
                  fetching: false,
                  success: true,
                  unique_key: v4()
                });

                break;
              }
            }
          }
        }

        return result;
      } catch (err) {
        Logger.log(
          `Failed to update state for task id: ${task_id}, u_ref: ${u_ref} error: ${err.message}`
        );

        return result;
      }
    }

    return result;
  }

  async saveUser(profile_id = '') {
    const result = {
      err: true
    };

    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;

        if (!isLoggedIn) {
          return;
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: { profile_id },
          url: `/v1/task/save-user?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data) {
          result.err = !res.data.success;
        }
      } catch (err) {
        Logger.log(
          `Failed to save user profileID: ${profile_id} error: ${err.message}`
        );
      }
    }

    return result;
  }

  checkSavedUsersPage(page) {
    return this.tasks_saved_users_pages.indexOf(page);
  }

  getSaveUsersMaxPage() {
    return this.tasks_saved_users_max_page;
  }

  getSavedUsers(page = 1) {
    if (this.tasks_saved_users_pages.indexOf(page) > -1) {
      return this.tasks_saved_users[page];
    } else {
      return [];
    }
  }

  onSaveUsersResults(fn) {
    if (this.resultsFromGettingSavedUsersOBS) {
      return this.resultsFromGettingSavedUsersOBS.subscribe({ next: fn });
    }

    return null;
  }

  async savedUsers(page = 1) {
    const result = {
      valid: false,
      value: [],
      has_next: false
    };

    if (this.profile_context) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;

        if (!isLoggedIn) {
          return;
        }

        if (this.resultsFromGettingSavedUsersOBS) {
          this.resultsFromGettingSavedUsersOBS.next({
            valid: true,
            fetching: true,
            page
          });
        }

        const { token, id, lb, n, username_ref } = user.auth;
        const res = await axios({
          method: 'POST',
          baseURL:
            k.API_DASHBOARD_URLS[
              Store.CONFIGS.IS_DEV ? 'development' : 'production'
            ],
          headers: {
            authorization: `BEARER ${token}`,
            'x-custom-user-session': `${username_ref}`
          },
          data: { page },
          url: `/v1/task/saved-users?auth_token_id=${id}&lb=${lb}&n=${n}`,
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });

        if (res && res.data && res.data.valid) {
          result.value = res.data.value;
          result.has_next = res.data.has_next;
          result.valid = true;

          if (typeof res.data.max_page === 'number') {
            this.tasks_saved_users_max_page = res.data.max_page;
          }

          this.tasks_saved_users[page] = res.data.value;

          if (this.tasks_saved_users_pages.indexOf(page) < 0) {
            this.tasks_saved_users_pages.push(page);
          }
        }
      } catch (err) {
        Logger.log(`Failed to fetch saved users w error: ${err.message}`);
      }
    }

    if (this.resultsFromGettingSavedUsersOBS) {
      this.resultsFromGettingSavedUsersOBS.next({
        value: result.value,
        valid: result.valid,
        fetching: false,
        page
      });
    }

    return result;
  }

  async uploadImage(u_ref = '', blob = null) {
    const result = { err: true, url: '', maxSize: false };
    if (this.profile_context) {
      try {
        if (window.FormData && blob) {
          const formData = new FormData();
          const { isLoggedIn, user } = this.profile_context.state;

          if (isLoggedIn) {
            formData.append('file', blob, `${v4()}`);

            const { token, id, lb, n, username_ref } = user.auth;
            const res = await axios({
              method: 'POST',
              baseURL:
                k.API_OPEN_URLS[
                  Store.CONFIGS.IS_DEV ? 'development' : 'production'
                ],
              headers: {
                authorization: `BEARER ${token}`,
                'x-custom-user-session': `${username_ref}`,
                'content-type': 'multipart/form-data'
              },
              data: formData,
              url: `/v1/file/upload/image?auth_token_id=${id}&lb=${lb}&n=${n}&u_ref=${u_ref}`,
              validateStatus: function (status) {
                return status >= 200 && status <= 500;
              }
            });

            if (res && res.data) {
              if (res.data.success) {
                result.err = false;
                result.url = res.data.url;
              }

              result.maxSize =
                res.data.max_size_reached || res.data.max_size_task;
            }
          }
        }
      } catch (err) {
        Logger.log(`Failed to upload image in task: ${u_ref}`);
      }
    }

    return result;
  }

  async attachTags(tags = [], task_id = '', u_ref = '') {
    // [tag_id]
    const result = { success: false };

    if (this.profile_context && Array.isArray(tags)) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;
        let task_current = null;

        for (let i = 0; i < this.tasks_current.length; i++) {
          const t = this.tasks_current[i];

          if (t && t.task_id === task_id && t.universal_ref_map === u_ref) {
            if (
              Array.isArray(t.personal_tags) &&
              t.personal_tags.indexOf('none') >= 0
            ) {
              t.personal_tags.splice(t.personal_tags.indexOf('none'), 1);
            }

            for (const tag_id of tags) {
              if (t.personal_tags && t.personal_tags.indexOf(tag_id) < 0) {
                this.tasks_current[i].personal_tags.push(tag_id);
              }
            }

            task_current = this.tasks_current[i];
            break;
          }
        }

        if (isLoggedIn && task_current) {
          this.gettingUserTasksOBS.next({
            fetching: false,
            success: true,
            unique_key: v4()
          });

          const { token, id, lb, n, username_ref } = user.auth;
          const res = await axios({
            method: 'POST',
            baseURL:
              k.API_OPEN_URLS[
                Store.CONFIGS.IS_DEV ? 'development' : 'production'
              ],
            headers: {
              authorization: `BEARER ${token}`,
              'x-custom-user-session': `${username_ref}`
            },
            data: {
              u_ref,
              tags: tags.slice(0, 3).map(t => this.tagIDToName(t)),
              task_id: task_id.toLowerCase()
            },
            url: `/v1/task/attach-tags?auth_token_id=${id}&lb=${lb}&n=${n}&u_ref=${u_ref}`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          });

          if (res && res.data && res.data.success) {
            result.success = true;
          } else {
            for (const tag_id of tags) {
              let idx = task_current.personal_tags.indexOf(tag_id);

              if (task_current.personal_tags && idx >= 0) {
                task_current.personal_tags.splice(idx, 1);
              }
            }

            if (
              Array.isArray(task_current.personal_tags) &&
              task_current.personal_tags.length < 1
            ) {
              task_current.personal_tags.push('none');
            } else if (!task_current.personal_tags) {
              task_current.personal_tags = ['none'];
            }

            this.gettingUserTasksOBS.next({
              fetching: false,
              success: true,
              unique_key: v4()
            });
          }
        } else {
          Logger.log(`Trying to attach tags to task but not logged in`);
        }
      } catch (err) {
        Logger.log(
          `Failed to attach tags to task_id: ${task_id} u_ref: ${u_ref} w error: ${err.message}`
        );
      }
    }

    return result;
  }

  async removeTags(tags = [], task_id = '', u_ref = '') {
    // [tag_id]
    const result = { success: false };

    if (this.profile_context && Array.isArray(tags)) {
      try {
        const { isLoggedIn, user } = this.profile_context.state;

        if (isLoggedIn) {
          const { token, id, lb, n, username_ref } = user.auth;
          const res = await axios({
            method: 'POST',
            baseURL:
              k.API_OPEN_URLS[
                Store.CONFIGS.IS_DEV ? 'development' : 'production'
              ],
            headers: {
              authorization: `BEARER ${token}`,
              'x-custom-user-session': `${username_ref}`
            },
            data: {
              u_ref,
              tags: tags.slice(0, 3).map(t => this.tagIDToName(t)),
              task_id: task_id.toLowerCase()
            },
            url: `/v1/task/remove-tags?auth_token_id=${id}&lb=${lb}&n=${n}&u_ref=${u_ref}`,
            validateStatus: function (status) {
              return status >= 200 && status <= 500;
            }
          });

          if (res && res.data && res.data.success) {
            result.success = true;

            for (let i = 0; i < this.tasks_current.length; i++) {
              const t = this.tasks_current[i];

              if (t && t.task_id === task_id && t.universal_ref_map === u_ref) {
                for (const tag_id of tags) {
                  let idx = t.personal_tags.indexOf(tag_id);

                  if (t.personal_tags && idx >= 0) {
                    this.tasks_current[i].personal_tags.splice(idx, 1);
                  }
                }

                this.gettingUserTasksOBS.next({
                  fetching: false,
                  success: true,
                  unique_key: v4()
                });

                break;
              }
            }
          }
        } else {
          Logger.log(`Trying to attach tags to task but not logged in`);
        }
      } catch (err) {
        Logger.log(
          `Failed to attach tags to task_id: ${task_id} u_ref: ${u_ref} w error: ${err.message}`
        );
      }
    }

    return result;
  }

  async checkTagSettings() {
    const tasks = AppAPI.getLocalConfig('tasks');

    // first we check if there was already selected tag
    // we apply that filtered tag here
    if (tasks && tasks.selectedTags && Array.isArray(tasks.selectedTags)) {
      const tagId = tasks.selectedTags[0];

      if (
        ProfileAPI.USER_TASKS.tagExists(tagId, 'id') > -1 &&
        !ProfileAPI.USER_TASKS.hasFilteredTags()
      ) {
        ProfileAPI.USER_TASKS.setTag(tagId);
        await ProfileAPI.USER_TASKS.get('', false);
      }
    } else {
      await AppAPI.setLocalConfigs('tasks', {
        selectedTags: [],
        selectedState: []
      });
    }
  }
}

export default UserTasks;
