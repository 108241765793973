// import ProfileAPI from 'src/profile-manager/API';
// import styles from './index.scss';

let webPreviewBlot = null;

if (window.Quill) {
  const Quill = window.Quill;
  // experimental
  // const EmbedBlock = Quill.import('blots/embed');
  const OldLink = Quill.import('formats/link');

  webPreviewBlot = class WebPreviewBlot extends OldLink {
    static create(data) {
      const node = super.create(data?.url || '');

      if (data && data.url) {
        node.setAttribute('data-url', data.url);
      }

      return node;
    }

    // experimental
    // static create(data) {
    //   const node = super.create(data);

    //   function buildInnterHTML() {
    //     try {
    //       const div = document.createElement('div');
    //       const title = document.createElement('div');
    //       const desc = document.createElement('p');
    //       const cover = document.createElement('div');
    //       const anchor = document.createElement('a');

    //       div.classList.add(styles.web_preview);
    //       title.setAttribute('content-editable', false);
    //       desc.setAttribute('content-editable', false);
    //       div.setAttribute('content-editable', false);
    //       title.classList.add(styles.web_preview_title);
    //       desc.classList.add(styles.web_preview_description);
    //       cover.classList.add(styles.web_preview_cover);
    //       cover.append(anchor);

    //       if (data && data.url && (!data.title || !data.description)) {
    //         ProfileAPI.PUBLIC.getSiteInfo(data.url)
    //           .then(res => {
    //             if (
    //               res &&
    //               !res.err &&
    //               res.ogs &&
    //               res.ogs.title &&
    //               res.ogs.description
    //             ) {
    //               title.textContent = res.ogs.title;
    //               desc.textContent = res.ogs.description;
    //             }
    //           })
    //           .catch(console.log);
    //       }

    //       if (data && data.url) {
    //         anchor.setAttribute('href', data.url);
    //         anchor.setAttribute('target', '_blank');
    //       }

    //       if (data && data.title) {
    //         div.append(title);
    //         title.textContent = data.title;
    //       } else {
    //         // title.textContent = 'To fetch title haha';
    //         div.append(title);
    //       }

    //       if (data && data.description) {
    //         div.append(desc);
    //         desc.textContent = data.description;
    //       } else {
    //         // desc.textContent = 'To fetch description';
    //         div.append(desc);
    //       }

    //       div.appendChild(cover);

    //       return div;
    //     } catch (err) {
    //       return document.createElement('span');
    //     }
    //   }

    //   if (data && data.url) {
    //     node.setAttribute('data-url', data.url);
    //   }

    //   node.append(buildInnterHTML());
    //   node.setAttribute('content-editable', false);

    //   return node;
    // }

    static value(domNode) {
      const { url } = domNode.dataset;
      return { url };
    }

    index() {
      return 1;
    }
  };

  webPreviewBlot.blotName = 'webpreview';
  webPreviewBlot.className = 'ql-webpreview';
  webPreviewBlot.tagName = 'p';
}

export function destroyWebPreviewBlot() {
  if (webPreviewBlot) {
    webPreviewBlot = null;
  }
}

export default function () {
  return webPreviewBlot;
}
