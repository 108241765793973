import styles from './BetterImage.scss';
import UserDOM from 'src/lib/UserDOM';

export function sanitize(url, protocols) {
  const anchor = document.createElement('a');
  anchor.href = url;
  const protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
  return protocols.indexOf(protocol) > -1;
}

export function base64ImageSize(src = '', type = 'png') {
  return Math.round(
    ((src.length - `data:image/${type};base64,`.length) * 3) / 4
  );
}

const ATTRIBUTES = ['alt', 'height', 'width'];

export default function () {
  const Quill = window.Quill;
  const OldImage = Quill.import('formats/image');

  class BetterImage extends OldImage {
    static create(value) {
      const node = super.create(value);

      if (value) {
        if (typeof value === 'string') {
          node.setAttribute('src', this.sanitize(value));
        } else if (typeof value === 'object' && value) {
          const src = this.sanitize(value.src ? value.src : '');

          node.classList.add('low_opac_05');
          node.setAttribute('src', src);
        }

        if (UserDOM.isImage(node)) {
          node.classList.add(styles.better_image);
        }
      }

      return node;
    }

    static formats(domNode) {
      return ATTRIBUTES.reduce((formats, attribute) => {
        if (domNode.hasAttribute(attribute)) {
          formats[attribute] = domNode.getAttribute(attribute);
        }
        return formats;
      }, {});
    }

    static match(url) {
      return (
        /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url)
      );
    }

    static register() {
      if (/Firefox/i.test(navigator.userAgent)) {
        setTimeout(() => {
          // Disable image resizing in Firefox
          document.execCommand('enableObjectResizing', false, false);
        }, 1);
      }
    }

    static sanitize(url) {
      return sanitize(url, ['http', 'https', 'data']) ? url : '//:0';
    }

    static value(domNode) {
      if (domNode) {
        if (UserDOM.isImage(domNode)) {
          return domNode.getAttribute('src');
        } else {
          const img = domNode.querySelector('img');

          if (img) {
            return img.getAttribute('src');
          }
        }
      }

      return '';
    }

    format(name, value) {
      if (ATTRIBUTES.indexOf(name) > -1) {
        if (value) {
          this.domNode.setAttribute(name, value);
        } else {
          this.domNode.removeAttribute(name);
        }
      } else {
        super.format(name, value);
      }
    }
  }

  Quill.register('formats/image', BetterImage, true);
}
