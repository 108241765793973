import Store from 'src/lib/store';
import k from 'src/constants/k';
import Logger from 'src/lib/Logger';
import { Subject } from 'rxjs';

class AppAPI {
  globalConfigChangesOBS = null;
  localConfigChangesOBS = null;
  onAppFocusChangeOBS = null;

  constructor() {
    this.globalConfigChangesOBS = new Subject();
    this.localConfigChangesOBS = new Subject();
    this.onAppFocusChangeOBS = new Subject();
  }

  onAppUnload(fn) {
    if (this.app_context) {
      this.app_context.onAppUnload(fn);
    }
  }

  removeOnAppUnload(fn) {
    if (this.app_context && typeof fn === 'function') {
      const idx = this.app_context.APP_CB_UNLOAD.indexOf(fn);

      if (idx >= 0) {
        this.app_context.APP_CB_UNLOAD.splice(idx, 1);
      }
    }
  }

  setContext(app_context) {
    this.app_context = app_context;
  }

  getLocalConfig(key) {
    if (this.app_context) {
      return this.app_context.state.localConfigs[`${key}`];
    }

    return undefined;
  }

  getGlobalConfig(key) {
    if (this.app_context) {
      return this.app_context.state.globalConfigs[`${key}`];
    }

    return undefined;
  }

  onGlobalConfigChange(fn) {
    if (this.app_context) {
      return this.globalConfigChangesOBS.subscribe({ next: fn });
    }

    return null;
  }

  onLocalConfigChange(fn) {
    if (this.app_context) {
      return this.localConfigChangesOBS.subscribe({ next: fn });
    }

    return null;
  }

  async setGlobalConfig(key, value) {
    if (this.app_context) {
      const { globalConfigs } = this.app_context.state;

      if (typeof globalConfigs[key] !== 'undefined') {
        globalConfigs[`${key}`] = value;
        await this.app_context.setStateAsync({
          globalConfigs
        });

        this.globalConfigChangesOBS.next({ key, value });
      }
    }
  }

  async setLocalConfigs(key, value) {
    if (this.app_context) {
      const { localConfigs } = this.app_context.state;

      if (typeof localConfigs[key] !== 'undefined') {
        localConfigs[`${key}`] = value;
        Store.CONFIGS.app[`${key}`] = value;

        await this.app_context.setStateAsync({
          localConfigs
        });

        this.localConfigChangesOBS.next({ key, value });
      }
    }
  }

  setMetaTitle(title = '') {
    try {
      title = title.replace(/["]+/g, '');
      const metas = document.querySelectorAll('.meta_title');
      const titleDOM = document.querySelector('title');
      if (titleDOM) titleDOM.innerText = title;

      for (let m of metas) {
        if (m && m.hasAttribute('content')) {
          m.setAttribute('content', title);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  setMetaDescription(desc = '') {
    try {
      if (desc.length >= 0) {
        desc = desc.replace(/['"]+/g, '');
        const metas = document.querySelectorAll('.meta_description');

        for (let m of metas) {
          if (m && m.hasAttribute('content')) {
            m.setAttribute('content', desc);
          }
        }
      }
    } catch (err) {}
  }

  async pushRecentSearch(kw = '') {
    if (this.app_context) {
      const { localConfigs } = this.app_context.state;

      if (typeof kw === 'string' && kw.length > 0) {
        const { recentSearch } = localConfigs;

        if (
          Array.isArray(recentSearch) &&
          recentSearch.length >= k.RECENT_SEARCHES_MAX_STORED
        ) {
          recentSearch.shift();
          recentSearch.push(kw);
        } else if (Array.isArray(recentSearch)) {
          if (recentSearch.indexOf(kw) < 0) {
            recentSearch.push(kw);
          }
        } else {
          const recentSearch = [];
          recentSearch.push(kw);
          Store.CONFIGS.app['recentSearch'] = recentSearch;
          localConfigs.recentSearch = recentSearch;
        }

        await this.app_context.setStateAsync({
          localConfigs
        });
      }
    }
  }

  async removeRecentSearch(kw = '') {
    if (this.app_context) {
      const { localConfigs } = this.app_context.state;

      if (typeof kw === 'string' && kw.length > 0) {
        const { recentSearch } = localConfigs;

        if (Array.isArray(recentSearch)) {
          const idx = recentSearch.indexOf(kw);
          if (idx > -1) {
            recentSearch.splice(idx, 1);

            await this.app_context.setStateAsync({
              localConfigs
            });
          }
        }
      }
    }
  }

  copyTextToClipboard(text = '') {
    return new Promise(resolve => {
      text = `${text}`;
      const textArea = document.createElement('textarea');
      const valueSTR = text;
      Logger.log(`Copying to clipboard: ${text}`);

      textArea.value = valueSTR;
      textArea.setAttribute('value', valueSTR);
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      textArea.setSelectionRange(0, valueSTR.length);

      if (document.execCommand && document.execCommand('copy')) {
        Logger.log(`Success copied: ${valueSTR}`);

        textArea.remove();
        return resolve({ err: false });
      } else if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(valueSTR).then(
          () => {
            Logger.log(`Success copied: ${valueSTR}`);

            textArea.remove();
            return resolve({ err: false });
          },

          err => {
            Logger.log(
              `Failed to copy text on clipboard ${
                err && err.message ? ' :' + err.message : ''
              }`
            );

            textArea.remove();
            return resolve({ err: true });
          }
        );
      } else {
        textArea.remove();
        return resolve({ err: true });
      }
    });
  }

  async setLastScroll(x, y) {
    if (this.app_context) {
      if (typeof x === 'number' && typeof y === 'number' && x > -1 && y > -1) {
        const { globalConfigs } = this.app_context.state;

        if (typeof globalConfigs['lastScroll'] !== 'undefined') {
          globalConfigs['lastScroll']['x'] = x;
          globalConfigs['lastScroll']['y'] = y;

          await this.app_context.setStateAsync({
            globalConfigs
          });

          this.globalConfigChangesOBS.next({
            key: 'lastScroll',
            value: globalConfigs['lastScroll']
          });
        }
      }
    }
  }

  onAppFocusChange(fn) {
    if (this.onAppFocusChangeOBS) {
      return this.onAppFocusChangeOBS.subscribe({ next: fn });
    }

    return null;
  }

  focusChanged() {
    if (this.onAppFocusChangeOBS) {
      this.onAppFocusChangeOBS.next();
    }
  }

  isBlur() {
    return Store.CONFIGS.onBlur;
  }

  isOnline() {
    return Store.OS.USER_ONLINE;
  }

  appendQueryURL(basePath = '', config = {}) {
    if (!this.app_context) {
      return;
    }

    Store.CONFIGS.getQueryJSON();
    const { history } = this.app_context.props;
    const path = window.location.pathname;
    const query = Store.CONFIGS.getQuery();
    const finalPath =
      basePath && typeof basePath === 'string'
        ? basePath
        : path.charAt(0) === '/'
        ? path
        : '/' + path;
    const configKeys = Object.keys(config || {});

    if (configKeys && configKeys.length) {
      for (const k of configKeys) {
        if (`${config[k]}`.length < 1) {
          delete query[k];
        } else query[k] = config[k];
      }
    }

    const search = Object.keys(query)
      .map((k, idx) => {
        if (query[k] === '') {
          return '';
        }

        return `${idx > 0 ? '&' : '?'}${k}=${query[k]}`;
      })
      .join('');

    if (history) {
      history.push(
        (finalPath && finalPath.charAt(finalPath.length - 1) === '/'
          ? finalPath.substring(0, finalPath.length - 1)
          : finalPath) + (search ? search : '')
      );
      Store.CONFIGS.getQueryJSON();
    }
  }
}

export default new AppAPI();
