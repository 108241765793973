class BottomPromptAPI {
  static context = null;
  static closeOnNewMount = false;

  constructor() {
    this.closeOnNewMount = false;
    this.deactivatePromptDOM = this.deactivatePromptDOM.bind(this);
  }

  setContext(ctx) {
    this.context = ctx;
  }

  setPromptDOM(dom) {
    if (this.context) {
      this.context.setPromptDOM(dom);
    }
  }

  deactivatePromptDOM() {
    if (this.context) {
      this.context.deactivatePromptDOM();
    }
  }
}

export default new BottomPromptAPI();
