import styles from './index.scss';
import { v4 } from 'uuid';
let twitterBlot = null;

if (window.Quill) {
  const Quill = window.Quill;
  const loadScript = function (url = '') {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.onload = function () {
        resolve(true);
      };
      script.onerror = function () {
        reject();
      };
      document.head.appendChild(script);
    });
  };
  const EmbedBlock = Quill.import('blots/embed');

  twitterBlot = class TwitterBlot extends EmbedBlock {
    static create(data) {
      const node = super.create(data);
      const uniqueId = v4();

      function buildInnerHtml(data, uniqueId = '') {
        const div = document.createElement('div');
        const img = document.createElement('img');
        div.setAttribute('id', uniqueId);
        div.id = uniqueId;
        div.contentEditable = false;
        div.classList.add(styles.twitter_container);
        div.classList.add(styles.twitter_container_default);
        img.classList.add(styles.img_placeholder);

        if (!window.twitter) {
          window.twitter = () => {
            if (!window.twttr) {
              loadScript('//platform.twitter.com/widgets.js').then(() => {
                const timeoutId = setTimeout(() => {
                  try {
                    window.twttr.widgets.load();
                  } catch {}

                  try {
                    const div = document.getElementById(uniqueId);
                    if (div) {
                      div.classList.remove(styles.twitter_container_default);
                      div.classList.add(styles.twitter_container_flex);
                    }
                    // const dom = document.querySelector(`a[href='${data.url}']`);
                  } catch {}

                  clearTimeout(timeoutId);
                }, 100);
              });
            } else {
              try {
                window.twttr.widgets.load();
              } catch {}

              try {
                const div = document.getElementById(uniqueId);
                if (div) {
                  div.classList.remove(styles.twitter_container_default);
                  div.classList.add(styles.twitter_container_flex);
                }
              } catch {}
            }
          };
        } else if (window.twttr) {
          div.classList.remove(styles.twitter_container_default);
          div.classList.add(styles.twitter_container_flex);
        }

        div.innerHTML = `<blockquote class="twitter-tweet"><div class=${
          styles.anchor_cover
        }><a href="${data.url}">${
          data.url || 'Twitter'
        }</a></div></blockquote>`;
        div.appendChild(img);

        img.onload = event => {
          if (event) {
            event.stopPropagation();
            event.preventDefault();
          }

          window.twitter();
          img.remove();
        };
        img.src = '/ogicon.png';

        return div;
      }

      node.appendChild(buildInnerHtml(data, uniqueId));
      node.setAttribute('data-url', data.url);
      // store data
      return node;
    }

    static value(domNode) {
      const { url } = domNode.dataset;
      return { url };
    }

    index() {
      return 1;
    }
  };

  twitterBlot.blotName = 'twitter';
  twitterBlot.className = 'ql-twitter';
  twitterBlot.tagName = 'div';
}

export function destroyTwitterBlot() {
  if (twitterBlot) {
    twitterBlot = null;
  }
}

export default function () {
  return twitterBlot;
}
