import axios from 'axios';
import Logger from 'src/lib/Logger';
import Store from 'src/lib/store';
import { Subject, interval, timer } from 'rxjs';

class OS {
  USER_ONLINE = true;
  userConnectivityChangeOBS = null;
  userConnectivityWatcherSubscriber = null;
  ONLINE_INTERVAL_CHECK = 25000;
  OFFLINE_INTERVAL_CHECK = 6000;
  OS_NAME = 'n/a';
  OS_BROWSER = 'n/a';
  PRODUCT = '';

  constructor() {
    this.userConnectivityChangeOBS = new Subject();
  }

  async start() {
    this.getOSInfo();
    await this.checkConnectivity(true);
    this.userConnectivityWatch(true);
  }

  onConnectivityChange(fn) {
    return this.userConnectivityChangeOBS.subscribe({ next: fn });
  }

  getOSInfo() {
    try {
      const ua = window.navigator.userAgent;

      if (`${ua}`.length > 0) {
        const info = window.platform.parse(ua);

        if (info) {
          if (info.os && info.os.toString) {
            this.OS_NAME = info.os.toString();
          } else {
            this.OS_NAME = info.os;
          }

          this.OS_BROWSER = info.name;
          this.PRODUCT = info.product ? `${info.product}` : '';
        }

        if (this.PRODUCT) {
          Logger.log(`User machine product: ${this.PRODUCT}`);
        }

        console.log(`DASHBOARD: User on: ${info.name} ${info.os}`);
      }
    } catch (err) {
      console.log(`DASHBOARD: Failed getting user OS info: ${err.message}`);
    }
  }

  checkConnectivity(onLaunch = false) {
    return new Promise(async resolve => {
      let _resolved = false;
      let res = null;
      const _timeout = timer(4000).subscribe(() => {
        if (_timeout) {
          _timeout.unsubscribe();
        }

        _resolved = true;

        return resolve();
      });

      if (Store.CONFIGS.onBlur && !onLaunch) {
        clearTimeout(_timeout);
        return resolve();
      } else if (window.navigator.onLine) {
        res = await axios({
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Origin': '*'
          },
          baseURL: 'https://app.getchamu.com/_p/connect', //'https://jsonplaceholder.typicode.com/todos/1',
          validateStatus: function (status) {
            return status >= 200 && status <= 500;
          }
        });
      }

      if (_resolved) {
        return;
      }

      if (
        res &&
        typeof res === 'object' &&
        res.status >= 200 &&
        res.status <= 300
      ) {
        if (!this.USER_ONLINE) {
          Logger.log('User is online');
          this.userConnectivityChangeOBS.next({
            online: true
          });
        } else if (onLaunch) {
          Logger.log('User is online');
        }

        this.USER_ONLINE = true;
      } else {
        if (this.USER_ONLINE) {
          Logger.log('User is offline');
          this.userConnectivityChangeOBS.next({
            online: false
          });
        } else if (onLaunch) {
          Logger.log('User is offline');
        }

        this.USER_ONLINE = false;
      }

      clearTimeout(_timeout);
      return resolve();
    });
  }

  userConnectivityWatch(onLaunch = false) {
    const _t = this.USER_ONLINE
      ? this.ONLINE_INTERVAL_CHECK
      : this.OFFLINE_INTERVAL_CHECK;

    Logger.log(`Listening to user connectivity changes interval: ${_t}`);

    if (this.userConnectivityWatcherSubscriber) {
      this.userConnectivityWatcherSubscriber.unsubscribe();
    }

    this.userConnectivityWatcherSubscriber = interval(_t).subscribe(
      async () => {
        const prevOnlineStatus = !!this.USER_ONLINE;
        await this.checkConnectivity(onLaunch);

        if (prevOnlineStatus && !this.USER_ONLINE) {
          /**
           * From online to offline
           */
          Logger.log(`Going from online to offline`);

          if (this.userConnectivityWatcherSubscriber) {
            this.userConnectivityWatcherSubscriber.unsubscribe();
            this.userConnectivityWatcherSubscriber = null;
            return this.userConnectivityWatch();
          }
        } else if (!prevOnlineStatus && this.USER_ONLINE) {
          /**
           * From offline to online
           */
          Logger.log(`Going from offline to online`);
          if (this.userConnectivityWatcherSubscriber) {
            this.userConnectivityWatcherSubscriber.unsubscribe();
            this.userConnectivityWatcherSubscriber = null;
            return this.userConnectivityWatch();
          }
        }
      }
    );
  }
}

export default OS;
