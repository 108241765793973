import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './ButtonClick.scss';
import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';

const ButtonClick = props => {
  const localButtonRef = useRef(null);

  useEffect(() => {
    let localButtonClickSubscriber = null;
    if (localButtonRef && localButtonRef.current) {
      localButtonClickSubscriber = fromEvent(localButtonRef.current, 'click')
        .pipe(
          filter(e => e),
          map(e => e)
        )
        .subscribe(e => {
          const target = e.target
            ? e.target
            : e.srcElement
            ? e.srcElement
            : null;

          if (typeof props.onClick === 'function' && !props.disabled) {
            props.onClick(target);
          }
        });
    }

    return () => {
      if (localButtonClickSubscriber) {
        localButtonClickSubscriber.unsubscribe();
      }
    };
    // eslint-disable-next-line
  }, [props, props.onClick, props.disabled]);

  return (
    <div
      className={cx(styles.button_click, {
        [styles.button_click_animate]: Boolean(props.animate),
        [props.className]:
          typeof props.className === 'string' && `${props.className}`.length > 0
      })}
    >
      <button
        style={{
          boxShadow: 'none',
          outline: 'none',
          position: 'relative',
          background: 'none',
          cursor: 'pointer'
        }}
        ref={localButtonRef}
        className={cx({ [props.buttonClassname]: !!props.buttonClassname })}
      >
        {props.children}
      </button>
    </div>
  );
};

export default ButtonClick;
