import React from 'react';
import styles from './Formats.scss';
import i18n, { Markdown, URLMarkdown } from 'src/locales';

const Formats = () => {
  return (
    <div className={styles.docs_formats}>
      <div className={styles.label}>
        <h2>{i18n('docs_format_task_label')}</h2>{' '}
      </div>
      <div className={styles.message}>
        <p>{i18n('docs_format_task_message')}</p>
      </div>
      <div className={styles.title}>
        <h3>{i18n('docs_format_task_check_list')}</h3>
      </div>
      <div className={styles.task_description_only}>
        <ul data-checked={'true'}>
          <li> {i18n('docs_format_task_check_list_checked_one')}</li>
          <li> {i18n('docs_format_task_check_list_checked_two')}</li>
        </ul>
      </div>
      <div className={styles.title}>
        <h3>{i18n('docs_format_task_ordered_list')}</h3>
      </div>
      <div className={styles.task_description_only}>
        <ol>
          <li> {i18n('docs_format_task_ordered_list_one')}</li>
          <li> {i18n('docs_format_task_ordered_list_two')}</li>
        </ol>
      </div>
      <div className={styles.title}>
        <h3>{i18n('docs_format_task_youtube_link')}</h3>
      </div>
      <div className={styles.message}>
        {Markdown('docs_format_task_youtube_link_message', {
          emph1: null,
          emph2: null,
          emph3: null
        })}
      </div>
      <div className={styles.task_description_only}>
        <div className={styles.youtube}>
          <div>
            <img
              src={'https://img.youtube.com/vi/dQw4w9WgXcQ/maxresdefault.jpg'}
              alt={'Youtube preview sample from chamu'}
            ></img>
          </div>
          <div className={styles.cover}>
            <div className={styles.ql_youtube_play}></div>
            <a
              target={'_blank'}
              href={'https://www.youtube.com/watch?v=dQw4w9WgXcQ'}
              rel="noopener noreferrer"
            >
              {' '}
            </a>
          </div>
        </div>
      </div>
      <div className={styles.title}>
        <h3>{i18n('docs_format_task_images')}</h3>
      </div>
      <div className={styles.message}>
        {Markdown('docs_format_task_images_message', {
          emph1: null,
          emph2: null
        })}
      </div>

      <div className={styles.resources}>
        <h5>
          {URLMarkdown(
            'docs_introduction_questions_or_redirect',
            {
              emph1: {
                link: '#',
                notRedirect: true
              },
              url1: {
                link: 'https://getchamu.com'
              }
            },
            '',
            true
          )}
        </h5>
      </div>
    </div>
  );
};

export default Formats;
