import React from 'react';
import styles from './About.scss';
import i18n, { Markdown } from 'src/locales';

const AboutUs = () => {
  return (
    <div className={styles.docs_about}>
      <div className={styles.label}>
        <h2>{i18n('docs_about_us_label')}</h2>
      </div>
      <div className={styles.msg_one}>
        {Markdown('docs_about_us_content_message_one', {
          title1: null
        })}
      </div>
      <div className={styles.msg_two}>
        {Markdown('docs_about_us_content_message_two', { emph1: null })}
      </div>
      <div className={styles.mission}>
        <h3>
          <span>{'\u201C'}</span>
          {i18n('docs_about_us_content_mission')}
          <span>{'”'}</span>
        </h3>
      </div>
      <div className={styles.keys}>
        <div>
          <ul>
            <li>
              <div className={styles.ic_fast}></div>
              <div className={styles.key_desc}>
                <h4>{i18n('docs_about_us_keys_fast_title')}</h4>
                <h5>{i18n('docs_about_us_keys_fast_description')}</h5>
              </div>
            </li>{' '}
            <li>
              <div className={styles.ic_accessible}></div>
              <div className={styles.key_desc}>
                <h4>{i18n('docs_about_us_keys_accessible_title')}</h4>
                <h5>{i18n('docs_about_us_keys_accesssible_description')}</h5>
              </div>
            </li>{' '}
            <li>
              <div className={styles.ic_secure}></div>
              <div className={styles.key_desc}>
                <h4>{i18n('docs_about_us_keys_secure_title')}</h4>
                <h5>{i18n('docs_about_us_keys_secure_description')}</h5>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
