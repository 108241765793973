export default {
  API_DASHBOARD_URLS: {
    development: 'http://localhost:8080',
    production: 'https://stage-api.getchamu.com'
  },
  API_OPEN_URLS: {
    development: 'http://localhost:8081',
    production: 'https://stage-api-open.getchamu.com'
  },
  WEB_DASHBOARD_URLS: {
    development: 'https://stage-app.getchamu.com',
    production: 'https://stage-app.getchamu.com'
  },
  USERNAME_PROPERTIES: {
    maxLength: 35,
    minLength: 8
  },
  PASSWORD_PROPERTIES: {
    minLength: 8,
    maxLength: 60
  },
  FIRSTNAME_PROPERTIES: {
    maxLength: 110,
    minLength: 2
  },
  EMAIL_PROPERTIES: {
    maxLength: 320,
    minLength: 7
  },
  LASTNAME_PROPERTIES: {
    maxLength: 110,
    minLength: 2
  },
  DISPLAY_PICTURE_PROPERTIES: { maxSizeKB: 100 },
  MONTHS_INDEX: [
    { name: 'January', abv: 'Jan.' },
    {
      name: 'February',
      abv: 'Feb.'
    },
    {
      name: 'March',
      abv: 'Mar.'
    },
    {
      name: 'April',
      abv: 'Apr.'
    },
    {
      name: 'May',
      abv: 'May'
    },
    {
      name: 'June',
      abv: 'Jun.'
    },
    {
      name: 'July',
      abv: 'Jul.'
    },
    {
      name: 'August',
      abv: 'Aug.'
    },
    {
      name: 'September',
      abv: 'Sept.'
    },
    {
      name: 'October',
      abv: 'Oct.'
    },
    {
      name: 'November',
      abv: 'Nov.'
    },
    {
      name: 'December',
      abv: 'Dec.'
    }
  ],
  USER_HEADER_MORE_OPTIONS: [
    {
      key: 'account',
      i18n: 'user_dashboard_account_settings_title',
      name: 'account',
      isLink: true,
      link: '/user/account'
    },
    {
      key: 'logout',
      i18n: 'user_dashboard_account_logout_title',
      name: 'logout'
    }
  ],
  USER_TASK_TITLE_PROPERTIES: { maxLength: 80, minLength: 2 },
  USER_TASK_DESCRIPTION_PROPERTIES: {
    maxSizeKB: 800, // 800KB, 500 for now temporary
    maxImageWidth: 1280,
    maxImageSizeKB: 15360
  },
  USER_TASK_COMMENT_PROPERTIES: {
    maxLength: 250,
    min: 1
  },
  USER_TASK_MAX_SIZE_KB: 11000, // 11 MB
  USER_TASK_DESCRIPTION_PRIVACY_OPTS: [
    { key: 'private', i18n: 'user_task_privacy_private', idx: [1] },
    { key: 'public', i18n: 'user_task_privacy_public', idx: [2, 3] }
  ],
  // 1 - private (can be shared by manually adding watchers/followers for the task with given profile ID)
  // 2 - public (no password)
  // 3 - public (w password)
  USER_TASK_PRIVACY_ARR: [1, 2, 3],
  USER_TASK_PRIVACY_PUBLIC: [2, 3],
  USER_TASK_PRIVACY_PRIVATE: 1,
  USER_TASK_PRIVACY_PUBLIC_NO_PW: 2,
  USER_TASK_PRIVACY_PUBLIC_W_PW: 3,
  USER_TASK_PRIVACY_PW_PROPERTIES: { minLength: 5, maxLength: 15 },
  USER_TASK_MAX_TAGS: 3,
  USER_TAG_MAX_BATCH: 10,
  USER_TAG_MAX_CREATE: 20,
  USER_TASKS_MAX_BATCH: 20,
  USER_TASK_PASSWORD_MAX: 25,
  USER_TASK_PASSWORD_MAX_TRIES: 5,
  USER_TASK_ACCESS_SUBSCRIBER: 'SUBSCRIBER',
  USER_TASK_ACCESS_EDITOR: 'EDITOR',
  USER_NOTIFICATIONS_MAX_BATCH: 20,
  MAX_TASK_W_ACCESS: 200,
  MAX_TASK_SUBSCRIBERS: 20,
  USER_TAG_NAME_PROPERTIES: { maxLength: 30, minLength: 2 },
  USER_REPORT_PROPERTIES: { comment: { maxLength: 250, minLength: 8 } },
  TASK_PRIORITIES: [
    { name: 'Low', key: 'low', i18n: 'user_task_priority_low_title', idx: 1 },
    {
      name: 'Normal',
      key: 'normal',
      i18n: 'user_task_priority_normal_title',
      idx: 2
    },
    { name: 'High', key: 'high', i18n: 'user_task_priority_high_title', idx: 3 }
  ],
  TASK_PRIORITIES_OBJ: {
    low: {
      name: 'Low',
      key: 'low',
      i18n: 'user_task_priority_low_title',
      idx: 1
    },
    normal: {
      name: 'Normal',
      key: 'normal',
      i18n: 'user_task_priority_normal_title',
      idx: 2
    },
    high: {
      name: 'High',
      key: 'high',
      i18n: 'user_task_priority_high_title',
      idx: 3
    }
  },
  TASK_CREATE_SUCCESS_TRANSFER_KEY: 'TASK_CREATE_SUCCESS_TRANSFER_KEY',
  COMMAND_SHIFT: [16],
  COMMAND_ENTER: [13],
  COMMAND_CTRL: [91, 17, 224],
  SPECIAL_KEYS_ARR: [8, 16, 17, 18, 46, 91],
  SPECIAL_KEYS: {
    8: { name: 'backspace', k: 8 },
    16: { name: 'shift', k: 16 },
    17: { name: 'ctrl', k: 17 },
    18: { name: 'alt', k: 18 },
    46: { name: 'delete', k: 46 },
    91: { name: 'cmd', k: 91 }
  },
  NAVIGATION_KEYS_ARR: [37, 38, 39, 40],
  NAVIGATION_KEYS: {
    37: { name: 'leftArrow', k: 37 },
    38: { name: 'upArrow', k: 38 },
    39: { name: 'rightArrow', k: 39 },
    40: { name: 'downArrow', k: 40 }
  },
  TASKS_STATE_KIND_ARR_DASHBOARD_ORDER: ['active', 'inactive', 'created'],
  TASKS_STATE_KIND_ARR: ['active', 'inactive', 'created'],
  TASKS_STATES_OBJ: {
    active: {
      name: 'Active',
      key: 'active',
      idx: 1,
      i18n: 'user_edit_task_state_active_title'
    },
    inactive: {
      name: 'Inactive',
      key: 'inactive',
      idx: 2,
      i18n: 'user_edit_task_state_inactive_title'
    },
    resolved: {
      name: 'Inactive',
      key: 'inactive',
      idx: 2,
      i18n: 'user_edit_task_state_inactive_title'
    },
    created: {
      name: 'Created',
      key: 'created',
      idx: 3,
      i18n: 'user_edit_task_state_created_title'
    }
  },
  TASKS_STATES_DASHBOARD: [
    {
      name: 'Active',
      key: 'active',
      idx: 1,
      i18n: 'user_task_view_active_title'
    },
    {
      name: 'Inactive',
      key: 'inactive',
      idx: 2,
      i18n: 'user_task_view_resolved_title'
    },
    {
      name: 'Created',
      key: 'created',
      idx: 3,
      i18n: 'user_task_view_created_title'
    }
  ],
  TASKS_STATES: [
    {
      name: 'Active',
      key: 'active',
      idx: 1,
      i18n: 'user_edit_task_state_active_title'
    },
    {
      name: 'Inactive',
      key: 'inactive',
      idx: 2,
      i18n: 'user_edit_task_state_inactive_title'
    }
  ],
  TASK_STATE_ACTIVE: 'active',
  TASK_STATE_CREATED: 'created',
  TASK_STATE_INACTIVE: 'inactive',
  TASKS_COMMENT_MAX_BATCH_FETCH: 20,
  RECENT_SEARCHES_MAX_STORED: 10,
  CREATE_TASK_MODE: { CREATE: 'create', EDIT: 'edit' },
  GLOBAL_CONFIG_TASK_FILTER_ALL: { idx: 0 },
  COUNTRIES_WHITELIST: ['ph', 'PH', 'SG', 'sg', 'MY', 'my'],
  USER_INPUT_SPECIAL_CHARS: [
    '@',
    '!',
    '#',
    '$',
    '%',
    '&',
    '^',
    ')',
    '(',
    '*',
    '+',
    '-',
    '_',
    '<',
    '>',
    '{',
    '}',
    '[',
    ']',
    ':',
    ';',
    '?'
  ],

  /**
   * User plan/subscription still to be improved
   */
  USER_SUBSCRIPTION_PLAN: ['free', 'individual', 'teams', 'enterprise'],
  USER_SUBSCRIPTION_PLAN_KEY: {
    free: {
      premium: false,
      team: [],
      tasks: 15, // per month
      sizePerTaskBytes: 25_000_000
    },
    individual: {
      premium: true,
      team: [],
      tasks: 'unli',
      sizePerTaskBytes: 50_000_000
    },
    teams: {
      premium: true,
      team: ['create', 'join', 'modify'],
      tasks: 'unli',
      sizePerTaskBytes: 50_000_000
    },
    enterprise: { premium: true, team: ['create', 'join', 'modify'] }
  },
  SPACE_TITLE_PROPERTIES: {
    minLength: 2,
    maxLength: 40
  },
  SPACE_BIO_PROPERTIES: {
    minLength: 0,
    maxLength: 80
  }
};
