import React from 'react';
import styles from './Tasks.scss';
import i18n, { URLMarkdown, Markdown } from 'src/locales';
import { withUserProfileSettings } from 'src/profile-manager';
import { Link } from 'react-router-dom';

const Tasks = props => {
  return (
    <div className={styles.docs_tasks}>
      <div className={styles.create_task_label}>
        <h2>{i18n('docs_tasks_create_label')}</h2>
      </div>
      <div className={styles.title}>
        <h3>{i18n('docs_tasks_how_to_create_title')}</h3>
      </div>
      <div className={styles.message}>
        {URLMarkdown(
          'docs_tasks_how_to_create_message_one',
          {
            url1: {
              link: props.isLoggedIn ? '/user' : '/login'
            }
          },
          '',
          false
        )}
        <div className={styles.create_task_button}>
          <div>
            <div></div>
          </div>
          <div className={styles.redirect_inline}>
            <h5>{i18n('docs_tasks_how_to_create_button_redirect')}</h5>
            <div> </div>
            <div className={styles.cover}>
              <Link
                to={props.isLoggedIn ? '/user/create-task' : '/login'}
              ></Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.title}>
        <h3>{i18n('docs_tasks_properties_title')}</h3>
      </div>
      <div className={styles.message}>
        {Markdown('docs_tasks_properties_message_one', {
          emph1: null,
          emph2: null
        })}
        <div className={styles.list_title_w_desc}>
          <ol>
            <li>
              <h5>
                <span>{i18n('docs_task_properties_priority_title')}</span>
                {'-'}
                {Markdown('docs_task_properties_priority_description', {
                  emph1: null,
                  emph2: null,
                  emph3: null,
                  emph4: null
                })}
              </h5>
            </li>
            <li>
              <h5>
                <span>{i18n('docs_task_properties_privacy_title')}</span>
                {'-'}
                {Markdown('docs_task_properties_privacy_description', {
                  emph1: null,
                  emph2: null,
                  emph3: null
                })}
              </h5>
            </li>
            <li>
              <h5>
                <span>{i18n('docs_task_properties_state_title')}</span>
                {'-'}
                {Markdown('docs_task_properties_state_description', {
                  emph1: null,
                  emph2: null
                })}
              </h5>
            </li>
            <li>
              <h5>
                <span>{i18n('common_tags')}</span>
                {'-'}
                {Markdown('docs_task_properties_tags_description', {
                  emph1: null
                })}
              </h5>
            </li>
            <li>
              <h5>
                <span>{i18n('docs_task_properties_access_title')}</span>
                {'-'}
                {Markdown('docs_task_properties_access_description', {
                  emph1: null,
                  emph2: null,
                  emph3: null
                })}
              </h5>
            </li>
          </ol>
        </div>
      </div>

      <div className={styles.title}>
        <h3>{i18n('docs_personal_tags_title')}</h3>
      </div>
      <div className={styles.message}>
        {Markdown('docs_personal_tags_description', {
          emph1: null,
          emph2: null,
          emph3: null
        })}
        {Markdown('docs_personal_tags_description_two', {
          emph1: null,
          emph2: null
        })}
        <div className={styles.create_tag_button}>
          <div>
            <div className={styles.create_tag_button_ic}></div>
            <h5>{i18n('user_dashboard_panel_tags_create')}</h5>
          </div>
          <div className={styles.redirect_inline}>
            <h5>{i18n('docs_personal_tags_create_button_redirect')}</h5>
            <div> </div>
            <div className={styles.cover}>
              <Link to={props.isLoggedIn ? '/user/new-tag' : '/login'}></Link>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.title}>
        <h3>{i18n('docs_task_comments')}</h3>
      </div>
      <div className={styles.message}>
        {Markdown('docs_task_comments_description', { emph1: null })}
        {Markdown('docs_task_comments_description_two', {
          emph1: null,
          emph2: null
        })}
      </div>
      <div className={styles.for_questions}>
        <h5>{Markdown('docs_tasks_for_questions', { emph1: null })}</h5>
      </div>
    </div>
  );
};

export default withUserProfileSettings(Tasks);
