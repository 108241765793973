import Store from 'src/lib/store';

class Logger {
  doLog = false;
  from = 'DASHBOARD';

  enable() {
    this.doLog = true;
  }

  serializeData(args) {
    const res = args.map(arg => {
      if (typeof arg === 'object' && arg !== null) return JSON.stringify(arg);
      return String(arg);
    });

    return res.join(' ');
  }

  log(...args) {
    if (this.doLog || Store.CONFIGS.IS_DEV) {
      const str = this.serializeData(args);
      console.log(`${this.from}: ${str}`);
    }
  }

  error(...args) {
    if (this.doLog || Store.CONFIGS.IS_DEV) {
      const str = this.serializeData(args);
      console.error(`${this.from}: ${str}`);
    }
  }

  info(...args) {
    if (this.doLog || Store.CONFIGS.IS_DEV) {
      const str = this.serializeData(args);
      console.log(`${this.from}: ${str}`);
      //@todo send to analytics service
    }
  }
}

export default new Logger();
